import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';

import * as fingerprint from 'fingerprintjs';

import { Invite } from './invite';
import { User } from '../users/user';

@Injectable({
  providedIn: 'root'
})
export class InviteService {

  private inviteURL = '/api/invite';

  constructor(private http: Http) { }

  // app.get("/api/invite/get_link")
  getInviteLink(
    inviterID: string,
    oneTime: boolean,
    inviteeEmail: string,
    inviterSchool: boolean,
    teacherAccessToken: string
  ): Promise<any> {

    var apiURL = this.inviteURL + "/get_link";
    apiURL += "?inviter_id=" + inviterID;
    apiURL += "&one_time=" + oneTime;
    apiURL += "&invitee_email=" + inviteeEmail;
    apiURL += "&inviter_school=" + inviterSchool;
    apiURL += "&teacher_access_token=" + teacherAccessToken;

    return this.http.get(apiURL)
                .toPromise()
                .then(response => response.json() as any)
                .catch(this.handleError);

  }

  // app.get("/api/invite/:invite_id")
  getInviteDetails(
    inviteID: string,
    teacherAccessToken: string
  ): Promise<any> {

    return this.http.get(this.inviteURL + "/" + inviteID)
                .toPromise()
                .then(response => response.json() as Invite)
                .catch(this.handleError);

  }

  // app.post("/api/invite/create_account")
  createAccount(
    inviteID: string,
    prefix: string,
    firstName: string,
    lastName: string,
    email: string,
    schoolName: string,
    schoolLocation: string,
    password: string,
    emailSubscribe: boolean
  ): Promise<any> {

    var accountDetails = {};
    accountDetails["invite_id"] = inviteID;
    accountDetails["prefix"] = prefix;
    accountDetails["first_name"] = firstName;
    accountDetails["last_name"] = lastName;
    accountDetails["email"] = email;
    accountDetails["school_name"] = schoolName;
    accountDetails["school_location"] = schoolLocation;
    accountDetails["password"] = password;
    accountDetails["contact_for_feedback"] = emailSubscribe;
    accountDetails["fingerprint"] = (new fingerprint()).get();
    accountDetails["user_agent"] = navigator.userAgent;

    return this.http.post(this.inviteURL + "/create_account", accountDetails)
                .toPromise()
                .then(response => response.json() as any)
                .catch(this.handleError);

  }

  // app.get("/api/invite/get_stats")
  getInviteStats(
    inviterID: string,
    teacherAccessToken: string
  ): Promise<any> {

    var apiURL = this.inviteURL + "/get_stats";
    apiURL += "?inviter_id=" + inviterID;
    apiURL += "&teacher_access_token=" + teacherAccessToken;

    return this.http.get(apiURL)
                .toPromise()
                .then(response => response.json() as any)
                .catch(this.handleError);

  }

  private handleError (error: any): Promise<any> {
    let errMsg = (error.message) ? error.message :
    error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(errMsg); // log to console
    return Promise.reject(errMsg);
  }

}
