import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

import { UserService } from '../users/user.service';
import { Globals } from '../globals';
import { TeacherService } from '../teacher/teacher.service';

@Component({
  selector: 'app-teacher',
  templateUrl: './teacher.component.html',
  styleUrls: ['./teacher.component.css']
})
export class TeacherComponent implements OnInit {

  title = 'Compare and Learn Home';

  lastClickTimestamp = -1;
  numClicks = 0;

  clickableElementInstantiated = false;

  constructor(
    private userService: UserService,
    private globals: Globals,
    private router: Router,
    private teacherService: TeacherService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {

    var self = this;

    this.userService.loginLocalUserSession(this.route.snapshot.paramMap.get('school_id'), true, false, null, true, function() {

      if (self.globals.user != null && self.globals.user.is_teacher == false) {

        if (self.globals.user && self.globals.user.school_id) {
          // Redirect to student page
          self.router.navigate(['/student/' + self.globals.user.school_id + '/', {}]);
        } else {
          self.router.navigate(['/home', {}]);  
        }
  
      } else {
  
        // Is a teacher, so make the welcome header clickable
  
        var clickableElement = document.getElementById('welcome');
  
        self.clickableElementInstantiated = true;
  
        clickableElement.onclick = function() {
  
          if (self.globals.user.super_access) {
  
            var currentTime = new Date().getTime();
  
            if (self.numClicks == 0) {
  
              self.numClicks++;
              self.lastClickTimestamp = new Date().getTime();
  
            } else if (self.numClicks == 1) {
  
              // Make sure the time between the first click and second click is at least 1 second and at most 3 seconds
              if (self.lastClickTimestamp < (currentTime - 1000) && self.lastClickTimestamp > (currentTime - 3000)) {
                self.numClicks++;
                self.lastClickTimestamp = new Date().getTime();
              } else {
                self.numClicks = 1;
                self.lastClickTimestamp = new Date().getTime();
              }
  
            } else if (self.numClicks == 2) {
  
              // Make sure the time between the second click and third click is at less than 1 second
              if (self.lastClickTimestamp > (currentTime - 1000)) {
  
                self.numClicks++;
  
                // Calculate an expiry 10 mins from now, store in the teacher entry and go to this as a parameter endpoint
                // to check it is valid against this teacher
                
                self.teacherService
                .setSuperAccessExpiry()
                .then((results: any) => {
          
                  // Go to the endpoint as per the parameter returned
                  if (results.expiry) {
                    self.router.navigate(['/teacher/stats/schools/' + results.expiry, {}]);
                  }
          
                });
  
              } else {
                self.numClicks = 1;
                self.lastClickTimestamp = new Date().getTime();
              }
  
            }
  
          }
  
        };
  
      }

    });

  }

  onTeacherDashboardClick() {
    this.router.navigate(['/teacher/dashboard/' + this.globals.user.school_id + '/', {}]);
  }

  onStudentViewClick() {
    this.router.navigate(['/student/' + this.globals.user.school_id + '/', {}]);
  }

  onInviteTeachers() {
    this.router.navigate(['/teacher/invite/' + this.globals.user.school_id + '/', {}]);
  }

  goHome(): void {
    this.router.navigate(['/home/', {}]);
  }

}

