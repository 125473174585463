import { Injectable } from '@angular/core';
import { User } from './users/user';

@Injectable()
export class Globals {

  user: User;
  accessChanged: boolean = false;
  school_id: string;
  
}
