import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import {Location} from '@angular/common';

import { TaskService }  from '../task/task.service';
import { Task } from 'app/task/task';
import { Globals } from '../globals';
import { UserService } from '../users/user.service';
import { TeacherService } from '../teacher/teacher.service';

@Component({
  selector: 'app-view-judges',
  templateUrl: './view-judges.component.html',
  styleUrls: ['./view-judges.component.css']
})
export class ViewJudgesComponent implements OnInit {

  task: Task;
  gettingTask: boolean = false;

  judgesArray: any[];

  superAccess : boolean = false;

  constructor(
    private taskService: TaskService,
    private router: Router,
    private globals: Globals,
    private userService: UserService,
    private route: ActivatedRoute,
    private teacherService: TeacherService,
    private location: Location,
    ) { }

  ngOnInit() {

    var self = this;

    this.userService.loginLocalUserSession(this.route.snapshot.paramMap.get('school_id'), true, false, null, true, function(user) {

      // Get the expiry parameter if it exists (for super access) and ensure it hasn't elapsed ...

      var expiry = self.route.snapshot.paramMap.get('expiry');

      if (expiry != null) {

        if (self.isInt(expiry) && parseInt(expiry) > new Date().getTime()) {

          // Expiry exists and is in-date, so check against their user entry that they have super access
          // and that the expiry in the param matches the super_expiry in their entry

          self.teacherService
          .isSuperAccessExpiry(parseInt(expiry))
          .then((results: any) => {

            if (results.success == true) {

              // Continue to render the page as the expiry is valid ...

              self.superAccess = true;
              self.getTask();

            } else {

              self.router.navigate(['/home/', {}]);

            }

          });

        } else {

          self.router.navigate(['/home/', {}]);

        }

      } else {

        self.getTask();

      }

    });

  }

  isInt(n) {
    return n % 1 === 0;
  }

  getTask() {

    if (this.superAccess) {

      this.getJudgesArray(true);

    } else if (this.route.snapshot.paramMap.get('expiry') == null) {

      this.getJudgesArray(false);

    }

  }

  getJudgesArray(isSuperAccess: boolean) {

    if (this.globals.user != null && this.globals.user.user_id != null && this.gettingTask == false) {

      this.gettingTask = true;

      const task_id: number = parseInt(this.route.snapshot.paramMap.get('task_id'));

      var userID = this.globals.user.user_id;
      if (isSuperAccess) {
        userID = this.route.snapshot.paramMap.get('teacher_id');
      }

      var schoolID = this.globals.user.school_id;
      if (isSuperAccess) {
        schoolID = this.route.snapshot.paramMap.get('school_id');
      }

      var superAccessTeacherID = null;
      if (isSuperAccess) {
        superAccessTeacherID = this.globals.user.user_id;
      }

      this.taskService
      .getTask(task_id, schoolID, userID)
      .then((task: Task) => {

        this.gettingTask = false;

        if (task != null && task.task_id == task_id) {

          this.task = task;

          this.teacherService
          .getJudgesArray(schoolID, task.task_id)
          .then((judgesArray: any[]) => {

            this.judgesArray = judgesArray;

          });

        } else {

          if (this.globals.user && this.task) {
            this.goBack();
          } else {
            this.goHome();
          }
          
        }

      });

    }

  }

  onDeleteJudgements(judgeUserID: number) {

    this.teacherService
    .deleteJudgements(this.globals.user.school_id, this.task.task_id, judgeUserID)
    .then((response: any[]) => {

      // Refresh the page by fetching the judgesArray again
      this.teacherService
      .getJudgesArray(this.globals.user.school_id, this.task.task_id)
      .then((judgesArray: any[]) => {

        this.judgesArray = judgesArray;

      });

    });

  }

  goBack(): void {

    var urlSuffix = "";
    var schoolID = this.globals.user.school_id;

    if (this.superAccess) {

      urlSuffix = this.route.snapshot.paramMap.get('teacher_id') + '/' + this.route.snapshot.paramMap.get('expiry') + '/';
      schoolID = this.route.snapshot.paramMap.get('school_id');

    }

    this.router.navigate(['/teacher/view_results/' + schoolID + '/' + this.task.task_id + '/' + urlSuffix, {}]);

  }

  goHome(): void {
    this.router.navigate(['/home/', {}]);
  }

}
