import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { InviteService } from '../invite/invite.service';
import { Globals } from '../globals';
import { UserService } from '../users/user.service';

@Component({
  selector: 'app-send-invite',
  templateUrl: './send-invite.component.html',
  styleUrls: ['./send-invite.component.css']
})
export class SendInviteComponent implements OnInit {

  sameSchoolLink: string;
  differentSchoolLink: string;
  fetchingLinks: boolean = false;
  inviteStats: any;

  constructor(
    private inviteService: InviteService,
    private router: Router,
    private globals: Globals,
    private userService: UserService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {

    var self = this;

    this.userService.loginLocalUserSession(this.route.snapshot.paramMap.get('school_id'), true, false, null, true, function(user) {

      self.fetchLinks();
      
    });  

  }

  fetchLinks() {

    if (this.globals.user != null && this.globals.user.user_id != null &&
      this.sameSchoolLink == null && this.differentSchoolLink == null &&
      this.globals.user.teacher_access_token != null && this.fetchingLinks == false) {

      this.fetchingLinks = true;

      this.inviteService
      .getInviteLink(
        this.globals.user.user_id,
        false, // one_time
        null, // invitee_email
        true,  // inviter_school
        (!this.globals.user ? "null" : this.globals.user.teacher_access_token)
      )
      .then((response: any) => {

        if (response != null && !response.error && response.invite_url) {

          this.sameSchoolLink = response.invite_url;

          this.inviteService
          .getInviteLink(
            this.globals.user.user_id,
            false, // one_time
            null, // invitee_email
            false,  // inviter_school
            (!this.globals.user ? "null" : this.globals.user.teacher_access_token)
          )
          .then((response2: any) => {

            if (response2 != null && !response2.error && response2.invite_url) {

              this.differentSchoolLink = response2.invite_url;

            } else {

              // Failed to get an invite url, so just return to the home page
              this.router.navigate(['/home', {}]);
    
            }

            this.inviteService
            .getInviteStats(
              this.globals.user.user_id,
              (!this.globals.user ? "null" : this.globals.user.teacher_access_token)
            )
            .then((response3: any) => {

              this.fetchingLinks = false;

              this.inviteStats = response3;

            });

          });

        } else {

          this.fetchingLinks = false;

          // Failed to get an invite url, so just return to the home page
          this.router.navigate(['/home', {}]);

        }

      });

    } else if (this.globals.user == null && (this.sameSchoolLink != null || this.differentSchoolLink != null)) {

      this.sameSchoolLink = null;
      this.differentSchoolLink = null;

    }

  }

  copySameSchoolLink() {

    this.copyToClipboard(this.sameSchoolLink, function() {

      var copyButton = document.getElementById("copy_same_school_link");
      copyButton.innerHTML = "Link Copied";
      copyButton.classList.remove("btn-default");
      copyButton.classList.remove("btn-primary");
      copyButton.classList.add("btn-primary");

      var copyButton = document.getElementById("copy_different_school_link");
      copyButton.innerHTML = "Copy Link";
      copyButton.classList.remove("btn-default");
      copyButton.classList.remove("btn-primary");
      copyButton.classList.add("btn-default");

    });

  }

  copyDifferentSchoolLink() {

    this.copyToClipboard(this.differentSchoolLink, function() {

      var copyButton = document.getElementById("copy_different_school_link");
      copyButton.innerHTML = "Link Copied";
      copyButton.classList.remove("btn-default");
      copyButton.classList.remove("btn-primary");
      copyButton.classList.add("btn-primary");

      var copyButton = document.getElementById("copy_same_school_link");
      copyButton.innerHTML = "Copy Link";
      copyButton.classList.remove("btn-default");
      copyButton.classList.remove("btn-primary");
      copyButton.classList.add("btn-default");

    });

  }

  copyToClipboard(str, callback) {

    const el = document.createElement('textarea');  // Create a <textarea> element
    el.value = str;                                 // Set its value to the string that you want copied
    el.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
    el.style.position = 'absolute';                 
    el.style.left = '-9999px';                      // Move outside the screen to make it invisible
    document.body.appendChild(el);                  // Append the <textarea> element to the HTML document
    const selected =            
      document.getSelection().rangeCount > 0        // Check if there is any content selected previously
        ? document.getSelection().getRangeAt(0)     // Store selection if found
        : false;                                    // Mark as false to know no selection existed before
    el.select();                                    // Select the <textarea> content
    document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
    document.body.removeChild(el);                  // Remove the <textarea> element
    if (selected) {                                 // If a selection existed before copying
      document.getSelection().removeAllRanges();    // Unselect everything on the HTML document
      document.getSelection().addRange(selected);   // Restore the original selection
    }

    if (callback) {
      callback();
    }

  }

  goBack(): void {
    this.router.navigate(['/home', {}]);
  }

  goHome(): void {
    this.router.navigate(['/home/', {}]);
  }

}
