import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { InviteService } from './invite.service';
import { Globals } from '../globals';
import { UserService } from '../users/user.service';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.css']
})
export class InviteComponent implements OnInit {

  inviteID: string;
  fetchingInvite: boolean = false;

  inviterFullName: string;
  inviterTeacherName: string;
  inviteeEmail: string;
  inviterSchool: boolean;
  oneTime: boolean;

  invalidInviteHeading: string;
  invalidInviteMessage: string;

  accountCreationForm: FormGroup;

  prefix: string;

  invalidPrefix: boolean = false;
  invalidFirstName: boolean = false;
  invalidLastName: boolean = false;
  invalidEmail: boolean = false;
  invalidSchoolName: boolean = false;
  invalidSchoolLocation: boolean = false;
  invalidPassword: boolean = false;
  formSubmissionError: string;

  constructor(
    private route: ActivatedRoute,
    private inviteService: InviteService,
    private router: Router,
    private globals: Globals,
    private userService: UserService,
  ) { }

  ngOnInit() {

    this.inviteID = this.route.snapshot.paramMap.get('invite_id')

    var self = this;

    this.accountCreationForm = new FormGroup({});

    this.userService.loginLocalUserSession(null, false, false, null, false, function(user) {

      self.fetchInvite();

    });  

  }

  fetchInvite() {

    if (!this.inviterFullName && !this.invalidInviteMessage && this.fetchingInvite == false) {

      this.fetchingInvite = true;

      this.inviteService
      .getInviteDetails(
        this.inviteID,
        (!this.globals.user ? "null" : this.globals.user.teacher_access_token)
      )
      .then((response: any) => {

        this.fetchingInvite = false;

        if (this.globals.user.is_teacher == true && (response != null && response.inviter_id != this.globals.user.user_id)) {

          // If the logged-in user is a teacher that isn't the same as the inviter of this invite, then redirect to the home page
          // (as they have no use of this account creation page and may be using this invite link to access the app)
          this.router.navigate(['/home/', {}]);

        } else if (response != null && !response.error && response.inviter_full_name) {
          
          this.inviterFullName = response.inviter_full_name;
          this.inviterTeacherName = response.inviter_teacher_name;
          this.inviteeEmail = (response.invitee_email == "null") ? null : response.invitee_email;
          this.inviterSchool = (response.inviter_school == "null") ? null : response.inviter_school;
          this.oneTime = (response.one_time == "true") ? true : false;

        } else {
          
          // Invalid invite_id, so return an appropriate message
          if (response.error && response.error == "expired") {
            this.invalidInviteHeading = "Invite Expired";
            this.invalidInviteMessage = "This invite has expired."
          } else if (response.error && response.error == "invalid_inviter_id") {
            this.invalidInviteHeading = "Invalid Invite";
            this.invalidInviteMessage = "I'm afraid we can't validate the person who sent this invite."
          } else {
            this.invalidInviteHeading = "Invalid Invite";
            this.invalidInviteMessage = "Invalid invite link."
          }

        }

      });

    }

  }

  onPrefixSelected(prefix: string) : void {

    this.prefix = prefix;

    var prefixLowerCase = prefix.toLowerCase();

    var prefixList = [
      "mr",
      "mrs",
      "miss",
      "ms",
      "dr",
      "prof"
    ];

    for (var i=0; i<prefixList.length; i++) {

      var currentPrefixButton : any = document.getElementById('button-' + prefixList[i]);

      if (prefixLowerCase == prefixList[i]) {

        currentPrefixButton.className = "btn btn-primary btn-space";

      } else {

        currentPrefixButton.className = "btn btn-secondary btn-space";

      }

    }

  }

  onCreateAccount(): void {

    // Get all the attributes ...
      // Note: the school name and location elements may not exist

    var firstName = this.toTitleCase((<HTMLInputElement>document.getElementById('first_name')).value);
    var lastName = this.toTitleCase((<HTMLInputElement>document.getElementById('last_name')).value);
    var email = (<HTMLInputElement>document.getElementById('email')).value;

    var schoolName = null;
    if (document.getElementById('school_name')) {
      schoolName = this.toTitleCase((<HTMLInputElement>document.getElementById('school_name')).value);
    }

    var schoolLocation = null;
    if (document.getElementById('school_location')) {
      schoolLocation = this.toTitleCase((<HTMLInputElement>document.getElementById('school_location')).value);
    }

    var password = (<HTMLInputElement>document.getElementById('password')).value;

    var emailSubscribe = (<HTMLInputElement>document.getElementById('email_subscribe')).checked;

    // Validate all the inputs first and return an error message if any are invalid / incomplete ...

    var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (this.prefix == null || this.prefix == "") {
      this.invalidPrefix = true;
    } else {
      this.invalidPrefix = false;
    }

    if (firstName == null || firstName == "") {
      this.invalidFirstName = true;
    } else {
      this.invalidFirstName = false;
    }

    if (lastName == null || lastName == "") {
      this.invalidLastName = true;
    } else {
      this.invalidLastName = false;
    }

    if (email == null || email == "" || !emailRegex.test(email) ) {
      this.invalidEmail = true;
    } else {
      this.invalidEmail = false;
    }

    if (document.getElementById('school_name') && (schoolName == null || schoolName == "")) {
      this.invalidSchoolName = true;
    } else {
      this.invalidSchoolName = false;
    }

    if (document.getElementById('school_location') && (schoolLocation == null || schoolLocation == "")) {
      this.invalidSchoolLocation = true;
    } else {
      this.invalidSchoolLocation = false;
    }

    if (password == null || password == "" || password.length < 6) {
      this.invalidPassword = true;
    } else {
      this.invalidPassword = false;
    }

    this.formSubmissionError = null;
    
    if (!this.invalidPrefix && !this.invalidFirstName && !this.invalidLastName && !this.invalidEmail &&
        !this.invalidSchoolName && !this.invalidSchoolLocation && !this.invalidPassword) {

      // Input is valid, so create the account and after auto-log them in

      var self = this;

      this.inviteService
      .createAccount(
        this.inviteID,
        this.prefix,
        firstName,
        lastName,
        email,
        schoolName,
        schoolLocation,
        password,
        emailSubscribe
      )
      .then((response: any) => {

        if (response != null && response.user_id) {

          // Set the user_id in client storage and the user in globals and navigate back to the home screen
          this.userService.saveUserID(response.user_id);
          this.userService.loginLocalUserSession(null, false, false, null, false, function(user) {

            self.router.navigate(['/home/', {}]);

          });

        } else if (response != null && response.error != null) {

          if (response.error == "email_in_use") {
            
            this.formSubmissionError = "An account already exists with that email address";

          } else {

            this.formSubmissionError = "Error when creating new account (" + response.error + ")";

          }

        } else {

          this.formSubmissionError = "An unknown error occurred when trying to create this account";

        }

      });

    }

  }

  toTitleCase(str) {
    return str.replace(/\w\S*/g, function(txt){
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  onShowHidePassword(): void {

    var passwordElement : HTMLInputElement = <HTMLInputElement>document.getElementById('password');
    var showHideButton : HTMLButtonElement = <HTMLButtonElement>document.getElementById('showHideButton');

    if (passwordElement.type == "password") {

      passwordElement.type = "text";
      showHideButton.innerText = "Hide";

    } else if (passwordElement.type = "text") {

      passwordElement.type = "password";
      showHideButton.innerText = "Show";

    }

  }

  goBack(): void {
    this.router.navigate(['/home', {}]);
  }

  goHome(): void {
    this.router.navigate(['/home/', {}]);
  }

}
