import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { TeacherService } from '../teacher/teacher.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  requestPasswordChangeForm: FormGroup;

  invalidEmail: boolean = false;
  invalidPassword: boolean = false;
  passwordsDontMatch: boolean = false;
  formSubmissionError: string;

  email: string;

  requestSuccessScreen: boolean = false;

  constructor(
    private teacherService: TeacherService,
  ) { }

  ngOnInit() {

    this.requestPasswordChangeForm = new FormGroup({});

  }

  onRequestPasswordChange(): void {

    // Get all the attributes ...

    this.email = (<HTMLInputElement>document.getElementById('email')).value;

    var newPassword = (<HTMLInputElement>document.getElementById('password_1')).value;
    var confirmPassword = (<HTMLInputElement>document.getElementById('password_2')).value;

    this.formSubmissionError = null;

    var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (this.email == null || this.email == "" || !emailRegex.test(this.email) ) {
      this.invalidEmail = true;
    } else {
      this.invalidEmail = false;
    }

    if (newPassword == null || newPassword == "" || newPassword.length < 6) {
      this.invalidPassword = true;
    } else {
      this.invalidPassword = false;
    }

    if (newPassword != confirmPassword) {
      this.passwordsDontMatch = true;
    } else {
      this.passwordsDontMatch = false;
    }

    if (!this.invalidEmail && !this.invalidPassword && !this.passwordsDontMatch) {

      // Input is valid ...

      var self = this;

      this.teacherService
      .requestPasswordChange(
        this.email,
        newPassword,
      )
      .then((response: any) => {

        if (response != null && response.success) {

          self.requestSuccessScreen = true;

        } else if (response != null && response.error != null) {

          if (response.error == "email_not_in_use") {
            
            this.formSubmissionError = "No account exists with that email address";

          } else {

            this.formSubmissionError = "Error when requesting password change (" + response.error + ")";

          }

        } else {

          this.formSubmissionError = "An unknown error occurred when trying to request this password change";

        }

      });

    }

  }

  
  onShowHidePassword(elementNumber: number): void {

    var passwordElement : HTMLInputElement = <HTMLInputElement>document.getElementById('password_' + elementNumber);
    var showHideButton : HTMLButtonElement = <HTMLButtonElement>document.getElementById('showHideButton_' + elementNumber);

    if (passwordElement.type == "password") {

      passwordElement.type = "text";
      showHideButton.innerText = "Hide";

    } else if (passwordElement.type = "text") {

      passwordElement.type = "password";
      showHideButton.innerText = "Show";

    }

  }

}
