import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

import { User } from './user';
import { Globals } from '../globals';
import { SchoolService } from '../school/school.service';

import * as fingerprint from 'fingerprintjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private userUrl = '/api/user';

  constructor (
    private http: Http,
    private globals: Globals,
    private router: Router,
    private route: ActivatedRoute,
    private schoolService: SchoolService,
  ) {}

  loginLocalUserSession(schoolIDParam: string, shouldCheckSchoolValidity: boolean,
    isStudentPage: boolean, redirectURL: string, shouldRedirect: boolean, callback) {

    var userID = this.getIsSharedDevice() ? sessionStorage.getItem("user_id") : localStorage.getItem("user_id");

    if (userID != null) {

      // There is a stored user_id, so log in as the user getting all their information (inc. teacher_access_token)
      // if they are a teacher

      this.getUser(userID)
      .then((user: User) => {

        if (user != null) {

          this.setGlobalUser(user, schoolIDParam, shouldCheckSchoolValidity, redirectURL, shouldRedirect, function() {

            if (callback) {
              callback(user);
            }
          });

        } else {

          this.globals.user = null;

          if (shouldRedirect) {

            if (isStudentPage) {
              this.router.navigateByUrl('/student/login/' +
                (schoolIDParam ? (schoolIDParam + '/') : "") +
                encodeURIComponent(this.router.url));
            } else {
              this.router.navigateByUrl('/teacher/login/' +
                (schoolIDParam ? (schoolIDParam + '/') : "") +
                encodeURIComponent(this.router.url));
            }

          }

        }

      });

    } else {

      // There is no stored user_id so set the global values to null and redirect to the login page (don't callback)

      this.globals.user = null;

      if (shouldRedirect) {

        if (isStudentPage) {
          this.router.navigateByUrl('/student/login/' +
                (schoolIDParam ? (schoolIDParam + '/') : "") +
                encodeURIComponent(this.router.url));
        } else {
          this.router.navigateByUrl('/teacher/login/' +
                (schoolIDParam ? (schoolIDParam + '/') : "") +
                encodeURIComponent(this.router.url));
        }

      }

    }

  }



  // get("/api/user/:user_id")
    // Called when an attempt is made to automatically login the local user session that already exists
  private getUser(userID: string): Promise<User> {
    return this.http.get(this.userUrl + '/' + userID)
               .toPromise()
               .then(response => response.json() as User)
               .catch(this.handleError);
  }

  // post("/api/user/create")
    // Called when a user logs in
  createUser(name: string, isSharedDevice: boolean, schoolID: string, schoolIDParam: string, redirectURL: string): void {

    localStorage.setItem("is_shared_device", "" + isSharedDevice);

    var newUser: User = new User();
    newUser.user_id = this.createAndSaveUserID();
    newUser.name = name;

    var userFingerprint = (new fingerprint()).get();

    var userAgent = navigator.userAgent;

    this.http.post(this.userUrl + "/create",
      { user: newUser, is_shared_device: isSharedDevice, school_id: schoolID,
        fingerprint: userFingerprint, user_agent: userAgent } )
      .toPromise()
      .then(response => this.setGlobalUser(response.json() as User, schoolIDParam, false, redirectURL, true, null))
      .catch(this.handleError);

  }

  // post("/api/user/create_as_teacher")
    // Called when a teacher submits work on behalf of a student
  createUserAsTeacher(name: string, schoolID: string, teacherID: string) {

    var isSharedDevice = this.getIsSharedDevice();

    var newUser: User = new User();
    newUser.user_id = this.makeid(20);
    newUser.name = name;

    var userFingerprint = (new fingerprint()).get();

    var userAgent = navigator.userAgent;

    return this.http.post(this.userUrl + "/create_as_teacher",
      { user: newUser, is_shared_device: isSharedDevice, school_id: schoolID,
        fingerprint: userFingerprint, user_agent: userAgent, teacher_id: teacherID } )
      .toPromise()
      .then(response => response.json())
      .catch(this.handleError);

  }

  // Called when a user session is logged in (either automatically from storage or by the user)
  private setGlobalUser(user: User, schoolIDParam: string, shouldCheckSchoolValidity: boolean,
    redirectURL: string, shouldRedirect: boolean, callback) {

    if (user.name) {

      // Add on their first name
      if (user.is_teacher) {
        user.first_name = user.name;
      } else {
        user.first_name = user.name.substr(0,user.name.indexOf(' '));
      }
      
      // Save the user in globals
      this.globals.user = user;

      var self = this;

      // Check the validity of the school parameter and redirect if not valid
      if (shouldCheckSchoolValidity) {

        this.checkSchoolValidity(schoolIDParam, function() {

          // School is valid (i.e. wasn't redirected yet), so redirect if a redirectURL is specified
          if (redirectURL && shouldRedirect) {
            self.router.navigateByUrl(redirectURL);
          } else if (callback) {
            callback();
          }
  
        });

      } else {

        if (redirectURL && shouldRedirect) {
          self.router.navigateByUrl(redirectURL);
        } else if (callback) {
          callback();
        }

      }

    }

  }

  private checkSchoolValidity(schoolIDParam, callbackIfNotRedirected) {

    var routerURLPrefix = this.router.url;

    if (schoolIDParam != null && schoolIDParam != "") {

      routerURLPrefix = this.router.url.replace(
        this.router.url.substring(
          this.router.url.indexOf(schoolIDParam)), "");

    } else {

      routerURLPrefix += "/";

    }

    if (this.globals.user && !this.globals.user.super_access) {

      this.schoolService
      .isValidSchool(schoolIDParam)
      .then((response: any) => {

        if (!response.success) {

          // School ID param is not valid ...
          
          if (this.globals.user && this.globals.user.school_id) {
            this.router.navigate([routerURLPrefix + this.globals.user.school_id, {}]);
          } else {
            this.router.navigate(['/home', {}]);
          }

        } else {

          // School ID param is valid ...

          if (this.globals.user && this.globals.user.school_id && schoolIDParam != this.globals.user.school_id) {

            this.router.navigate([routerURLPrefix + this.globals.user.school_id, {}]);

          } else {

            if (callbackIfNotRedirected) {
              callbackIfNotRedirected();
            }

          }

        }
      });

    } else {

      if (callbackIfNotRedirected) {
        callbackIfNotRedirected();
      }

    }

  }

  // Gets the local user_id (and creates it if one doesn't exist)
  private createAndSaveUserID() : string {

    // Generate a random string
    var userID = this.makeid(20);

    // Save in client storage
    this.saveUserID(userID);

    return userID;

  }

  saveUserID(userID: string) : void {

    // Save in client storage
    this.getIsSharedDevice() ? sessionStorage.setItem("user_id", userID) : localStorage.setItem("user_id", userID);

  }

  // Get the is_shared_device boolean
  getIsSharedDevice() : boolean {

    var isSharedDevice = localStorage.getItem("is_shared_device");

    if (isSharedDevice == null) {

      // If it's null, return false
      return false;

    } else {

      return JSON.parse(isSharedDevice);

    }

  }

  // Logs a user out
  logOutUser(schoolIDParam) {

    var isTeacher = null;

    if (this.globals.user) {
      if (this.globals.user.is_teacher) {
        isTeacher = true;
      } else {
        isTeacher = false;
      }
    }

    localStorage.removeItem("user_id");
    sessionStorage.removeItem("user_id");

    this.globals.user = null;

    this.removeAllAccessTaskCodesFromClientStorage();

    if (isTeacher == true) {

      this.router.navigateByUrl('/teacher/login/' +
              (schoolIDParam ? (schoolIDParam + '/') : "") +
              encodeURIComponent(this.router.url));

    } else {

      // If it's a student who has just logged out, check if the school_id set in the parameter of the URL is valid or not ...

      this.schoolService
      .isValidSchool(schoolIDParam)
      .then((response: any) => {

        if (response.success) {

          // If it's valid, redirect to the student login page as it will have a valid school_id set when they login (which
          // is required for the app to work properly for a student login)

          this.router.navigateByUrl('/student/login/' + schoolIDParam + '/' + encodeURIComponent(this.router.url));

        } else {

          // If it's not valid, redirect to the teacher login (as student logins don't work without a valid school_id as
          // a URL parameter)

          this.router.navigateByUrl('/teacher/login/' + encodeURIComponent(this.router.url));

        }

      });

    }

  }

  // Adds a task_id to the access_codes_for_task_ids array in client storage
  addTaskIDToAccessCodeArrayInClientStorage(task_id: number) {

    // Get the array if there is one

    var taskIDsArrayAsString = this.getIsSharedDevice() ?
                                  sessionStorage.getItem("access_codes_for_task_ids") :
                                  localStorage.getItem("access_codes_for_task_ids");

    var taskIDsArray = [];

    if (taskIDsArrayAsString != null) {
      taskIDsArray = JSON.parse(taskIDsArrayAsString);
    }

    // Add the task_id to the array
    taskIDsArray.push(task_id);

    // Save it back to client storage
    this.getIsSharedDevice() ? 
            sessionStorage.setItem("access_codes_for_task_ids", JSON.stringify(taskIDsArray)) :
            localStorage.setItem("access_codes_for_task_ids", JSON.stringify(taskIDsArray));

  }

  // Removes all access_task_codes from client storage
  removeAllAccessTaskCodesFromClientStorage() {

    // Get the array if there is one

    var taskIDsArrayAsString = this.getIsSharedDevice() ?
                                  sessionStorage.getItem("access_codes_for_task_ids") :
                                  localStorage.getItem("access_codes_for_task_ids");

    var taskIDsArray = [];

    if (taskIDsArrayAsString != null) {
      taskIDsArray = JSON.parse(taskIDsArrayAsString);
    }

    // Iterate through taskIDsArray and remove each associated access_task_code
    for (var i=0; i<taskIDsArray.length; i++) {

      var keyToRemove = "access_task_code_" + taskIDsArray[i];
      localStorage.removeItem(keyToRemove);
      sessionStorage.removeItem(keyToRemove);

    }

    // Finally remove access_codes_for_task_ids
    localStorage.removeItem("access_codes_for_task_ids");
    sessionStorage.removeItem("access_codes_for_task_ids");

  }
  

  private handleError (error: any): Promise<any> {
    let errMsg = (error.message) ? error.message :
    error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(errMsg); // log to console
    return Promise.reject(errMsg);
  }

  private makeid(length) {

    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;

  }

  // put("/api/user/console_log/:message")
  consoleLogToServer(message: string): Promise<any> {

    return this.http.get(this.userUrl + '/console_log/' + message)
    .toPromise()
    .then(response => response.json() as any)
    .catch(this.handleError);

  }

}
