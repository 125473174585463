import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { TaskService }  from '../task/task.service';
import { Task } from 'app/task/task';
import { Globals } from '../globals';
import { UserService } from '../users/user.service';
import { JudgementService }  from '../judgements/judgement.service';
import { Work } from '../work/work';
import { Judgement } from 'app/judgements/judgement';

@Component({
  selector: 'app-judge',
  templateUrl: './judge.component.html',
  styleUrls: ['./judge.component.css']
})
export class JudgeComponent implements OnInit {

  task: Task;
  
  taskID: number;
  accessTaskURL : string;

  judgementsArray : any[];

  timeStart : number;
  timeEnd : number;

  constructor(
    private route: ActivatedRoute,
    private taskService: TaskService,
    private location: Location,
    private router: Router,
    private globals: Globals,
    private userService: UserService,
    private judgementService: JudgementService,
  ) { }

  ngOnInit() {

    var self = this;

    var taskIDParam = this.route.snapshot.paramMap.get('task_id');
    this.taskID = (taskIDParam != null && this.isInt(taskIDParam)) ? parseInt(taskIDParam) : -1;

    this.userService.loginLocalUserSession(this.route.snapshot.paramMap.get('school_id'), true, true, null, true, function(user) {

      self.accessTaskURL = '/access-task/' + self.globals.user.school_id + '/' + self.taskID + '/' + encodeURIComponent(self.router.url);

      self.userService.getIsSharedDevice() ? 
        self.getTask(sessionStorage.getItem("access_task_code_" + self.route.snapshot.paramMap.get('task_id'))) :
        self.getTask(localStorage.getItem("access_task_code_" + self.route.snapshot.paramMap.get('task_id')));

    });

  }

  isInt(n) {
    return n % 1 === 0;
  }

  getTask(code: string) {

    if (this.globals.user != null && this.globals.user.user_id != null) {

      this.taskService
      .getTaskWithAccessCode(this.taskID, this.globals.user.school_id, code, this.globals.user.user_id)
      .then((task: Task) => {

        if (task != null && task.task_id == this.taskID) {

          if (task.can_judge) {

            this.task = task;
            this.updateWorkPair(code);

          } else {

            if (this.globals.user && this.globals.user.school_id) {
              this.router.navigate(['/task/' + this.globals.user.school_id + '/' + this.taskID, {}]);
            } else {
              this.router.navigate(['/home', {}]);  
            }

          }

        } else {

          this.router.navigateByUrl(this.accessTaskURL);

        }

      });

    } else if (this.globals.user == null && this.task != null) {

      this.task = null;
      this.router.navigateByUrl(this.accessTaskURL);

    }

  }

  updateWorkPair(code: string) {

    this.judgementService
    .getWorkPairToJudge(this.globals.user.school_id, this.task.task_id, this.globals.user.user_id, code)
    .then((judgementsArray: any[]) => {

      if (judgementsArray != null && judgementsArray.length == 2) {

        this.judgementsArray = judgementsArray;
        this.timeStart = new Date().getTime();
        
      } else {

        // No judgement to show, so navigate back to the task
        if (this.globals.user && this.globals.user.school_id) {
          this.router.navigate(['/task/' + this.globals.user.school_id +
            '/' + parseInt(this.route.snapshot.paramMap.get('task_id')), {}]);
        } else {
          this.router.navigate(['/home', {}]);  
        }

      }

    });

  }


  onJudgementButtonClick(buttonIndex: number) {

    this.timeEnd = new Date().getTime();
    var timeToJudgeInMilliseconds = this.timeEnd - this.timeStart;

    // First make sure that the judgement wasn't too fast
    if (timeToJudgeInMilliseconds < (this.task.min_time_to_judge_in_seconds * 1000)) {

      alert("That judgement was a little fast.  Make sure you properly review each work item before making a judgement.");

    } else {

      // Check can_judge is still true

      this.taskService
      .getTask(this.task.task_id, this.globals.user.school_id, this.globals.user.user_id)
      .then((task: Task) => {

        if (task.can_judge) {

          var worstPairToJudgeIndex = 0;
          if (buttonIndex == 0) {
            worstPairToJudgeIndex = 1;
          } else {
            worstPairToJudgeIndex = 0;
          }

          var thisJudgement: Judgement = new Judgement();
          thisJudgement.time_to_judge_in_milliseconds = timeToJudgeInMilliseconds;
          thisJudgement.task_id = this.task.task_id;
          thisJudgement.teacher_id = this.task.teacher_id;
          thisJudgement.user_id = this.globals.user.user_id;
          thisJudgement.user_name = this.globals.user.name;
          thisJudgement.school_id = this.globals.user.school_id;
          thisJudgement.best_work_id = this.judgementsArray[1].work_pair_to_judge[buttonIndex].work_id;
          thisJudgement.worst_work_id = this.judgementsArray[1].work_pair_to_judge[worstPairToJudgeIndex].work_id;

          // Save this judgement
            // This also checks to make sure both work items are still valid and haven't been deleted and only saves it if that's
            // the case (it won't inform the user either way though)
          this.judgementService
            .saveJudgement(thisJudgement)
            .then((response: any) => {

            if (response.error) {

              // Error inserting work document into work collection, so show to the user
              alert(response.error);

            } else {

              // Success uploading judgement (unless of course the work item was deleted before the judgement was made!) ...
              console.log("Success saving judgement");

              // Get a new work pair to judge if there is one, otherwise will return to the task list
              this.userService.getIsSharedDevice() ? 
                this.updateWorkPair(sessionStorage.getItem("access_task_code_" + this.route.snapshot.paramMap.get('task_id'))) :
                this.updateWorkPair(localStorage.getItem("access_task_code_" + this.route.snapshot.paramMap.get('task_id')));

            }

          });

        } else {

          alert("Your last judgement was not recorded because the teacher has closed the judgement phase");
          this.router.navigate(['/task/' + this.globals.user.school_id + '/'
            + parseInt(this.route.snapshot.paramMap.get('task_id')), {}]);

        }
      });

    }

  }


  goBack(): void {
    this.router.navigate(['/task/' + this.globals.user.school_id + '/' +
      parseInt(this.route.snapshot.paramMap.get('task_id')), {}]);
  }

}
