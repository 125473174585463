import { Injectable } from '@angular/core';
import { Task } from './task';
import { Http, Response } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  private tasksUrl = '/api/tasks';

  constructor(private http: Http) { }

  // get("/api/tasks/:school_id/:type/:user_id")
  getTasks(schoolID: string, type: string, userID: string): Promise<any[]> {

    // Lower case the type first
    type = type.toLowerCase();

    return this.http.get(this.tasksUrl + '/' + schoolID + '/' + type + '/' + userID)
                .toPromise()
                .then(response => response.json() as any[])
                .catch(this.handleError);

  }

  // get("/api/tasks/task/:school_id/:task_id/:user_id")
  getTask(taskID: number, schoolID: string, userID: string): Promise<Task> {

    return this.http.get(this.tasksUrl + '/task/' + schoolID + '/' + taskID + '/' + userID)
                .toPromise()
                .then(response => response.json() as Task)
                .catch(this.handleError);

  }
  

  // app.get("/api/tasks/access/:school_id/:task_id")
  getTaskWithAccessCode(taskID: number, schoolID: string, code: string, userID: string): Promise<any> {

    return this.http.get(this.tasksUrl + '/access/' + schoolID + "/" + taskID + '?code=' + code + '&user_id=' + userID)
                .toPromise()
                .then(response => response.json() as Task)
                .catch(this.handleError);

  }

  private handleError (error: any): Promise<any> {
    let errMsg = (error.message) ? error.message :
    error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(errMsg); // log to console
    return Promise.reject(errMsg);
  }

}
