import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { UserService } from '../users/user.service';
import { TeacherService } from '../teacher/teacher.service';
import { Globals } from '../globals';

import { saveAs } from 'file-saver/dist/FileSaver';

@Component({
  selector: 'app-teachers-stats',
  templateUrl: './teachers-stats.component.html',
  styleUrls: ['./teachers-stats.component.css']
})
export class TeachersStatsComponent implements OnInit {

  teachersStats : any;

  orderButtons: any[];
  currentOrder = 'tasks';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private teacherService: TeacherService,
    private globals: Globals,
  ) { }

  ngOnInit() {

    this.refreshPage();

  }

  refreshPage() {

    // Get the expiry parameter and ensure it hasn't elapsed ...

    var expiry = this.route.snapshot.paramMap.get('expiry');
    var currentTime = new Date().getTime();
    var self = this;

    if (expiry != null && this.isInt(expiry) && parseInt(expiry) > currentTime) {

      // Expiry is in-date, so now log the user in and check against their entry that they have super access
      // and that the expiry in the param matches the super_expiry in their entry

      this.userService.loginLocalUserSession(this.route.snapshot.paramMap.get('school_id'), true, false, null, true, function(user) {

        self.teacherService
        .isSuperAccessExpiry(parseInt(expiry))
        .then((results: any) => {

          if (results.success == true) {

            // Continue to render the page as the expiry is valid ...


            // Update the class lists of the order buttons (assuming the array has been instantiated - which only happens
            // the first time the user clicks one of the order buttons)

            if (self.orderButtons != null) {

              for (var i=0; i<self.orderButtons.length; i++) {

                self.orderButtons[i].classList.remove("btn-default");
                self.orderButtons[i].classList.remove("btn-primary");
                self.orderButtons[i].classList.add("btn-default");
  
              }
  
              document.getElementById(self.currentOrder).classList.add("btn-primary");

            }


            // Fetch the data in the specified order

            var schoolID = self.route.snapshot.paramMap.get('school_id');

            self.teacherService
            .getTeachersStats(
              self.globals.user.user_id,
              schoolID,
              self.globals.user.teacher_access_token,
              self.currentOrder,
            )
            .then((response: any[]) => {

              self.teachersStats = response;

            });

          } else {

            self.router.navigate(['/home/', {}]);

          }

        });

      });

    } else {

      // Invalid expiry, so return to teacher home page
      this.router.navigate(['/home/', {}]);

    }

  }

  isInt(n) {
    return n % 1 === 0;
  }

  downloadTeacherData() {

    this.teacherService.getTeacherData(
      this.globals.user.user_id,
      this.globals.user.teacher_access_token,
    )
    .then((dataArray: any[]) => {

      const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
      const header = Object.keys(dataArray[0]);
      let csv = dataArray.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
      csv.unshift(header.join(','));
      let csvArray = csv.join('\r\n');
      var blob = new Blob([csvArray], {type: 'text/csv' })
      saveAs(blob, "teacher-data.csv");

    });

  }

  orderBySchools() {
    this.router.navigate(['/teacher/stats/schools/' + this.route.snapshot.paramMap.get('expiry'), {}]);
  }

  orderBy(order) {

    // Instantiate the orderButtons array if not already instantiated
    if (this.orderButtons == null) {

      this.orderButtons = [
        document.getElementById("tasks"),
        document.getElementById("engaged_tasks"),
        document.getElementById("last_active"),
        document.getElementById("date_joined"),
        document.getElementById("first_name"),
        document.getElementById("last_name"),
        document.getElementById("invites_accepted"),
        document.getElementById("work_items"),
        document.getElementById("judgements"),
      ];

    }

    // Update the order and refresh the page
    this.currentOrder = order;
    this.refreshPage();

  }

  onSelect(schoolID: string, teacherID: string) {

    var sourceParam = "";

    var schoolIDParam = this.route.snapshot.paramMap.get('school_id');

    if (schoolIDParam == null || schoolIDParam == "null" || schoolIDParam == "") {

      sourceParam = "?source=teachers";

    }

    this.router.navigateByUrl('/teacher/dashboard/' + schoolID + '/' + teacherID + '/'
      + this.route.snapshot.paramMap.get('expiry') + sourceParam);

  }

  goBack(): void {
    this.router.navigate(['/teacher/stats/schools/' + this.route.snapshot.paramMap.get('expiry'), {}]);
  }

  goHome(): void {
    this.router.navigate(['/home/', {}]);
  }

}
