import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { acceptableNameValidator } from '../shared/acceptable-name.directive';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

import { TeacherService } from '../teacher/teacher.service';
import { User } from '../users/user';
import {Globals} from '../globals'
import { UserService } from '../users/user.service';

@Component({
  selector: 'app-create-new-task',
  templateUrl: './create-new-task.component.html',
  styleUrls: ['./create-new-task.component.css']
})
export class CreateNewTaskComponent implements OnInit {

  newTaskForm: FormGroup;

  constructor(
    private teacherService: TeacherService,
    private globals: Globals,
    private router: Router,
    private userService: UserService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {

    this.userService.loginLocalUserSession(this.route.snapshot.paramMap.get('school_id'), true, false, null, true, function(user) {});

    this.newTaskForm = new FormGroup({
      'subject': new FormControl("", [
        Validators.required
    ])});

    this.newTaskForm.addControl(
      'class', new FormControl("", [
        Validators.required
    ]));

    this.newTaskForm.addControl(
      'title', new FormControl("", [
        Validators.required
    ]));

  }

  onSubmit() {

    // Get the subject and ensure first letters of each word and only first letters are capitalised
    var capitalisedSubject = this.toTitleCase(this.newTaskForm.value.subject);

    // Force upper case any letters in class
    var capitalisedClass = this.newTaskForm.value.class.toUpperCase();

    // Get the title of the task
    var taskTitle = this.toTitleCase(this.newTaskForm.value.title);

    // Remove whitespace from both sides of all three strings
    capitalisedSubject = capitalisedSubject.trim();
    capitalisedClass = capitalisedClass.trim();
    taskTitle = taskTitle.trim();

    this.teacherService.createTask(capitalisedSubject, capitalisedClass, taskTitle)
    .then((returnValue: any) => {

      if (returnValue.success) {

        // Task added successfully, so return to the dashboard with the new task loaded
        this.router.navigate(['/teacher/dashboard/' + this.globals.user.school_id + '/', {}]);

      } else {

        // Not added, so show an alert
        var message = "Task failed to be created";
        if (returnValue.error) {
          message += ": " + returnValue.error;
        }
        alert(message);

      }

    });;

  }

  get subject() { return this.newTaskForm.get('subject'); }
  get class() { return this.newTaskForm.get('class'); }
  get title() { return this.newTaskForm.get('title'); }

  toTitleCase(str) {
    return str.replace(/\w\S*/g, function(txt){
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }


  goBack(): void {
    this.router.navigate(['/teacher/dashboard/' + this.globals.user.school_id + '/', {}]);
  }

  goHome(): void {
    this.router.navigate(['/home/', {}]);
  }

}
