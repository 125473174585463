import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';

import { Work } from '../work/work';
import { Judgement } from './judgement';

@Injectable({
  providedIn: 'root'
})
export class JudgementService {

  private judgementsUrl = '/api/judgements';

  constructor(private http: Http) { }

  // put("/api/judgements/save/")
  saveJudgement(judgement: Judgement): Promise<any> {

    return this.http.put(this.judgementsUrl + '/save/', judgement)
                .toPromise()
                .then(response => response.json())
                .catch(this.handleError);

  }

  // get("/api/judgements/new/:school_id/:task_id/:user_id")
  getWorkPairToJudge(schoolID: string, taskID: number, userID: string, code: string) : Promise<any[]> {

    return this.http.get(this.judgementsUrl + '/new/' + schoolID + '/' + taskID + '/' + userID + '?code=' + code)
                .toPromise()
                .then(response => response.json() as any[])
                .catch(this.handleError);

  }

  private handleError (error: any): Promise<any> {
    let errMsg = (error.message) ? error.message :
    error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(errMsg); // log to console
    return Promise.reject(errMsg);
  }

}
