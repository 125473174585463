import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

import { UserService } from '../users/user.service';
import { Globals } from '../globals';
import { TaskService } from '../task/task.service';
import { Task } from '../task/task';

@Component({
  selector: 'app-student',
  templateUrl: './student.component.html',
  styleUrls: ['./student.component.css']
})
export class StudentComponent implements OnInit {

  allTasks : any[];
  updatingTasks : boolean = false;

  constructor(
    private taskService: TaskService,
    private router: Router,
    private globals: Globals,
    private userService: UserService,
    private route: ActivatedRoute,
    ) { }

  ngOnInit() {

    var self = this;

    this.userService.loginLocalUserSession(this.route.snapshot.paramMap.get('school_id'), true, true, null, true, function(user) {

      self.updateTasks();

    });

  }

  copyStudentLink() {

    this.copyToClipboard("https://app.compareandlearn.com/#/student/" + this.globals.user.school_id, function() {

      var copyButton = document.getElementById("copy_student_link");
      copyButton.innerHTML = "Link Copied";
      copyButton.classList.add("btn-primary");

    });

  }

  updateTasks() {

    // Get tasks from the database

    if (this.globals.user != null && this.globals.user.user_id != null && this.allTasks == null && this.updatingTasks == false) {

      this.updatingTasks = true;

      this.taskService
      .getTasks(this.globals.user.school_id, "both", this.globals.user.user_id)
      .then((taskArray: any[]) => {

        this.allTasks = taskArray;
        this.updatingTasks = false;

      });

    } else if (this.globals.user == null && this.allTasks != null) {

      this.allTasks = null;

    }

  }

  onSelect(task: Task): void {

    this.router.navigate(['/task/' + this.globals.user.school_id + '/' + task.task_id, {}]);

  }

  copyToClipboard(str, callback) {

    const el = document.createElement('textarea');  // Create a <textarea> element
    el.value = str;                                 // Set its value to the string that you want copied
    el.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
    el.style.position = 'absolute';                 
    el.style.left = '-9999px';                      // Move outside the screen to make it invisible
    document.body.appendChild(el);                  // Append the <textarea> element to the HTML document
    const selected =            
      document.getSelection().rangeCount > 0        // Check if there is any content selected previously
        ? document.getSelection().getRangeAt(0)     // Store selection if found
        : false;                                    // Mark as false to know no selection existed before
    el.select();                                    // Select the <textarea> content
    document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
    document.body.removeChild(el);                  // Remove the <textarea> element
    if (selected) {                                 // If a selection existed before copying
      document.getSelection().removeAllRanges();    // Unselect everything on the HTML document
      document.getSelection().addRange(selected);   // Restore the original selection
    }

    if (callback) {
      callback();
    }

  }
  
  goBack(): void {
    this.router.navigate(['/home', {}]);
  }

}
