import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { UserService } from '../users/user.service';
import { TeacherService } from '../teacher/teacher.service';
import { Globals } from '../globals';

@Component({
  selector: 'app-schools-stats',
  templateUrl: './schools-stats.component.html',
  styleUrls: ['./schools-stats.component.css']
})
export class SchoolsStatsComponent implements OnInit {

  schoolsStats : any;

  orderButtons: any[];
  currentOrder = 'teachers';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private teacherService: TeacherService,
    private globals: Globals,
  ) { }

  ngOnInit() {

    this.refreshPage();

  }

  refreshPage() {

    // Get the expiry parameter and ensure it hasn't elapsed ...

    var expiry = this.route.snapshot.paramMap.get('expiry');
    var currentTime = new Date().getTime();
    var self = this;

    if (expiry != null && this.isInt(expiry) && parseInt(expiry) > currentTime) {

      // Expiry is in-date, so now log the user in and check against their entry that they have super access
      // and that the expiry in the param matches the super_expiry in their entry

      this.userService.loginLocalUserSession(this.route.snapshot.paramMap.get('school_id'), true, false, null, true, function(user) {

        self.teacherService
        .isSuperAccessExpiry(parseInt(expiry))
        .then((results: any) => {

          if (results.success == true) {

            // Continue to render the page as the expiry is valid ...


            // Update the class lists of the order buttons (assuming the array has been instantiated - which only happens
            // the first time the user clicks one of the order buttons)

            if (self.orderButtons != null) {

              for (var i=0; i<self.orderButtons.length; i++) {

                self.orderButtons[i].classList.remove("btn-default");
                self.orderButtons[i].classList.remove("btn-primary");
                self.orderButtons[i].classList.add("btn-default");
  
              }
  
              document.getElementById(self.currentOrder).classList.add("btn-primary");

            }


            // Fetch the data in the specified order

            self.teacherService
            .getSchoolsStats(
              self.globals.user.user_id,
              self.globals.user.teacher_access_token,
              self.currentOrder,)
            .then((response: any[]) => {

              self.schoolsStats = response;

            });

          } else {

            self.router.navigate(['/home/', {}]);

          }

        });

      });

    } else {

      // Invalid expiry, so return to teacher home page
      this.router.navigate(['/home/', {}]);

    }

  }

  isInt(n) {
    return n % 1 === 0;
  }

  orderByTeachers() {
    this.router.navigate(['/teacher/stats/teachers/' + this.route.snapshot.paramMap.get('expiry'), {}]);
  }

  orderBy(order) {

    // Instantiate the orderButtons array if not already instantiated
    if (this.orderButtons == null) {

      this.orderButtons = [
        document.getElementById("teachers"),
        document.getElementById("engaged_teachers"),
        document.getElementById("tasks"),
        document.getElementById("engaged_tasks"),
        document.getElementById("last_active"),
        document.getElementById("date_joined"),
        document.getElementById("school_name"),
        document.getElementById("school_location"),
        document.getElementById("work_items"),
        document.getElementById("judgements"),
      ];

    }

    // Update the order and refresh the page
    this.currentOrder = order;
    this.refreshPage();

  }

  onSelect(schoolID: string) {
    this.router.navigate(['/teacher/stats/teachers/' + schoolID + '/' + this.route.snapshot.paramMap.get('expiry'), {}]);
  }

  goBack(): void {
    this.router.navigate(['/home/', {}]);
  }

  goHome(): void {
    this.router.navigate(['/home/', {}]);
  }

}
