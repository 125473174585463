import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';

import { Work } from './work';

@Injectable({
  providedIn: 'root'
})
export class WorkService {

  private workUrl = '/api/work';

  constructor(private http: Http) { }

  // put("/api/work/create")
  createWork(
    task_id: number,
    teacher_id: string,
    user_id: string,
    user_name: string,
    school_id: string,
    timestamp: number,
    filesize: number,
    teacherIDWhoSubmittedWork: string,
  ): Promise<any> {

    var newWork: Work = new Work();
    newWork.task_id = task_id;
    newWork.teacher_id = teacher_id;
    newWork.user_id = user_id;
    newWork.user_name = user_name;
    newWork.school_id = school_id;
    newWork.timestamp = timestamp;
    newWork.filesize = filesize;
    newWork.created_by_teacher = teacherIDWhoSubmittedWork;

    return this.http.put(this.workUrl + "/create", newWork)
            .toPromise()
            .then(response => response.json())
            .catch(this.handleError);

  }

  private handleError (error: any): Promise<any> {
    let errMsg = (error.message) ? error.message :
    error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(errMsg); // log to console
    return Promise.reject(errMsg);
  }

}
