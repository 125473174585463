import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { UserService } from '../users/user.service';
import { TeacherService } from '../teacher/teacher.service';
import { Globals } from '../globals';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-edit-school',
  templateUrl: './edit-school.component.html',
  styleUrls: ['./edit-school.component.css']
})
export class EditSchoolComponent implements OnInit {

  selectedSchoolID: string;
  data: any;

  editSchoolForm: FormGroup;

  saveSchoolInfoFailed: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private teacherService: TeacherService,
    private globals: Globals,
  ) { }

  ngOnInit() {

    this.editSchoolForm = new FormGroup({});

    this.refreshPage();

  }

  refreshPage() {

    // Get the expiry parameter and ensure it hasn't elapsed ...

    var expiry = this.route.snapshot.paramMap.get('expiry');
    var currentTime = new Date().getTime();
    var self = this;

    if (expiry != null && this.isInt(expiry) && parseInt(expiry) > currentTime) {

      // Expiry is in-date, so now log the user in and check against their entry that they have super access
      // and that the expiry in the param matches the super_expiry in their entry

      this.userService.loginLocalUserSession(this.route.snapshot.paramMap.get('school_id'), true, false, null, true, function(user) {

        self.teacherService
        .isSuperAccessExpiry(parseInt(expiry))
        .then((results: any) => {

          if (results.success == true) {

            // Continue to render the page as the expiry is valid ...

            self.teacherService
            .getEditSchoolData(
              self.route.snapshot.paramMap.get('teacher_id'),
              self.route.snapshot.paramMap.get('school_id'),
              self.globals.user.teacher_access_token,
              self.globals.user.user_id)
            .then((editSchoolDataArray: any[]) => {
      
              self.data = editSchoolDataArray;
      
            });

          } else {

            self.router.navigate(['/home/', {}]);

          }

        });

      });

    } else {

      // Invalid expiry, so return to teacher home page
      this.router.navigate(['/home/', {}]);

    }

  }

  isInt(n) {
    return n % 1 === 0;
  }

  onSelect(schoolID: string, schoolName: string, schoolLocation: string) {

    this.selectedSchoolID = schoolID;

    var schoolNameInputElement: HTMLInputElement = <HTMLInputElement> document.getElementById("school_name");
    schoolNameInputElement.value = schoolName;
    schoolNameInputElement.disabled = true;

    var schoolLocationInputElement: HTMLInputElement = <HTMLInputElement> document.getElementById("school_location");
    schoolLocationInputElement.value = schoolLocation;
    schoolLocationInputElement.disabled = true;

  }

  save() {

    const submitButton = document.getElementById('submit');

    submitButton.innerHTML = "Saving ...";
    submitButton.className = "btn disabled";

    if (this.selectedSchoolID != null) {

      // Save a new school_id (this.selectedSchoolID) against the teacher (this.route.snapshot.paramMap.get('teacher_id'))

      var teacherID = this.route.snapshot.paramMap.get('teacher_id');

      console.log("Saving new school_id "  + this.selectedSchoolID + " against teacherID " + teacherID);
      
      var self = this;

      this.teacherService
      .changeSchool(teacherID, this.selectedSchoolID)
      .then((results: any) => {

        if (results.success == true) {

          self.saveSchoolInfoFailed = false;

          // Navigate back
          self.goBack();

        } else {

          self.saveSchoolInfoFailed = true;

          submitButton.innerHTML = "Save";
          submitButton.className = "btn btn-success";

        }

      });

    } else {

      // Update the name and location of the school_id already assigned to this teacher
        // school_id: this.route.snapshot.paramMap.get('school_id')
        // new_name: document.getElementById("school_name").value
        // new_location: document.getElementById("school_location").value

      var schoolID = this.route.snapshot.paramMap.get('school_id');
      var newSchoolName = (<HTMLInputElement> document.getElementById("school_name")).value;
      var newSchoolLocation = (<HTMLInputElement> document.getElementById("school_location")).value;

      console.log("Saving new school name (" + newSchoolName + ") and location (" + newSchoolLocation + ") for schoolID " + schoolID);

      var self = this;

      this.teacherService
      .updateSchoolInfo(schoolID, newSchoolName, newSchoolLocation)
      .then((results: any) => {

        if (results.success == true) {

          self.saveSchoolInfoFailed = false;

          // Navigate back
          self.goBack();

        } else {

          self.saveSchoolInfoFailed = true;

          submitButton.innerHTML = "Save";
          submitButton.className = "btn btn-success";

        }

      });

    }

  }

  goBack(): void {

    var schoolID = this.route.snapshot.paramMap.get('school_id');

    if (this.selectedSchoolID != null) {
      schoolID = this.selectedSchoolID;
    }


    this.router.navigate(['/teacher/dashboard/' +
      schoolID + '/' +
      this.route.snapshot.paramMap.get('teacher_id') + '/' +
      this.route.snapshot.paramMap.get('expiry'), {}]);

  }

  goHome(): void {
    this.router.navigate(['/home/', {}]);
  }

}
