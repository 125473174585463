import { Injectable } from '@angular/core';
import { Http } from '@angular/http';

import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class SchoolService {

  private schoolURL = '/api/school';

  constructor(private http: Http, private globals: Globals) { }

  // get("/api/school/is_valid/:school_id")
  isValidSchool(schoolID: string): Promise<any> {
    return this.http.get(this.schoolURL + '/is_valid/' + schoolID)
                .toPromise()
                .then(response => response.json() as any)
                .catch(this.handleError);
  }

  private handleError (error: any): Promise<any> {
    let errMsg = (error.message) ? error.message :
    error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(errMsg); // log to console
    return Promise.reject(errMsg);
  }

}
