export class Work {
    work_id: number;
    task_id: number;
    teacher_id: string;
    user_id: string;
    school_id: string;
    user_name: string;
    timestamp: number;
    filesize: number;
    tmp_image_url: string;
    created_by_teacher: string;
  }
