export class Judgement {
    task_id: number;
    teacher_id: string;
    user_id: string;
    school_id: string;
    user_name: string;
    best_work_id: number;
    worst_work_id: number;
    time_to_judge_in_milliseconds: number;
  }
