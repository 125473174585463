import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { TaskService }  from './task.service';
import { Task } from 'app/task/task';
import { Globals } from '../globals';
import { UserService } from '../users/user.service';

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.css']
})
export class TaskComponent implements OnInit {

  task: Task;

  taskID: number;
  accessTaskURL : string;

  constructor(
    private route: ActivatedRoute,
    private taskService: TaskService,
    private location: Location,
    private router: Router,
    private globals: Globals,
    private userService: UserService,
  ) { }

  ngOnInit() {

    var self = this;

    var taskIDParam = this.route.snapshot.paramMap.get('task_id');
    this.taskID = (taskIDParam != null && this.isInt(taskIDParam)) ? parseInt(taskIDParam) : -1;

    this.userService.loginLocalUserSession(this.route.snapshot.paramMap.get('school_id'), true, true, null, true, function(user) {

      self.accessTaskURL = '/access-task/' + self.globals.user.school_id + '/' + self.taskID + '/' + encodeURIComponent(self.router.url);

      self.userService.getIsSharedDevice() ? 
        self.getTask(sessionStorage.getItem("access_task_code_" + self.route.snapshot.paramMap.get('task_id')), null) :
        self.getTask(localStorage.getItem("access_task_code_" + self.route.snapshot.paramMap.get('task_id')), null);

    });

  }

  isInt(n) {
    return n % 1 === 0;
  }

  getTask(code: string, callback: any) {

    if (this.globals.user != null && this.globals.user.user_id != null) {

      this.taskService
      .getTaskWithAccessCode(this.taskID, this.globals.user.school_id, code, this.globals.user.user_id)
      .then((task: Task) => {

        if (task != null && task.task_id == this.taskID) {

          if (task.can_submit || task.can_judge) {

            this.task = task;

            if (callback) {
              callback();
            }

          } else {

            if (this.globals.user && this.globals.user.school_id) {
              this.router.navigate(['/student/' + this.globals.user.school_id + '/', {}]);
            } else {
              this.router.navigate(['/home', {}]);  
            }

          }

        } else {

          this.router.navigateByUrl(this.accessTaskURL);

        }

      });

    } else if (this.globals.user == null && this.task != null) {

      this.task = null;
      this.router.navigateByUrl(this.accessTaskURL);

    }

  }

  onSubmitTaskClick() {

    // Fetch the task first to get the most up-to-date values for the task's status

    var self = this;

    var accessCode = this.userService.getIsSharedDevice() ? 
      sessionStorage.getItem("access_task_code_" + this.route.snapshot.paramMap.get('task_id')) :
      localStorage.getItem("access_task_code_" + this.route.snapshot.paramMap.get('task_id'));

    this.getTask(accessCode, function() {

      if (self.task.can_submit && !self.task.submitted) {
        self.router.navigate(['/task/submit/' + self.globals.user.school_id + '/' + self.task.task_id, {}]);
      } else {
        alert("Submissions for this task have been switched off by the teacher");
      }

    });
    
  }

  onJudgeTaskClick() {

    // Fetch the task first to get the most up-to-date values for the task's status

    var self = this;

    var accessCode = this.userService.getIsSharedDevice() ? 
      sessionStorage.getItem("access_task_code_" + this.route.snapshot.paramMap.get('task_id')) :
      localStorage.getItem("access_task_code_" + this.route.snapshot.paramMap.get('task_id'));

    this.getTask(accessCode, function() {

      if (self.task.can_judge) {
        self.router.navigate(['/task/judge/' + self.globals.user.school_id + '/' + self.task.task_id, {}]);
      } else {
        alert("The teacher hasn't enabled the option to judge work for this task");
      }

    });

  }
  
  goBack(): void {
    this.router.navigate(['/student/' + this.globals.user.school_id + '/', {}]);
  }

}
