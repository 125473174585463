import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';

import { AppComponent } from './app.component';
import { StudentLoginComponent } from './student-login/student-login.component';

import { Globals } from './globals';
import { AppRoutingModule } from './/app-routing.module';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { KeysPipe } from './keys.pipe';
import { SubmitComponent } from './submit/submit.component';
import { JudgeComponent } from './judge/judge.component';
import { TopNavBarComponent } from './top-nav-bar/top-nav-bar.component';
import { TeacherDashboardComponent } from './teacher-dashboard/teacher-dashboard.component';
import { CreateNewTaskComponent } from './create-new-task/create-new-task.component';
import { ViewWorkComponent } from './view-work/view-work.component';
import { ArchivedTasksComponent } from './archived-tasks/archived-tasks.component';
import { ViewResultsComponent } from './view-results/view-results.component';
import { ViewJudgesComponent } from './view-judges/view-judges.component';
import { TeacherComponent } from './teacher/teacher.component';
import { StudentComponent } from './student/student.component';
import { TaskComponent } from './task/task.component';
import { AccessTaskComponent } from './access-task/access-task.component';
import { InviteComponent } from './invite/invite.component';
import { SendInviteComponent } from './send-invite/send-invite.component';
import { TeacherLoginComponent } from './teacher-login/teacher-login.component';
import { PermissionToContactComponent } from './permission-to-contact/permission-to-contact.component';
import { SchoolsStatsComponent } from './schools-stats/schools-stats.component';
import { TeachersStatsComponent } from './teachers-stats/teachers-stats.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { EditSchoolComponent } from './edit-school/edit-school.component';

@NgModule({
  declarations: [
    AppComponent,
    StudentLoginComponent,
    KeysPipe,
    SubmitComponent,
    JudgeComponent,
    TopNavBarComponent,
    TeacherDashboardComponent,
    CreateNewTaskComponent,
    ViewWorkComponent,
    ArchivedTasksComponent,
    ViewResultsComponent,
    ViewJudgesComponent,
    TeacherComponent,
    StudentComponent,
    TaskComponent,
    AccessTaskComponent,
    InviteComponent,
    SendInviteComponent,
    TeacherLoginComponent,
    PermissionToContactComponent,
    SchoolsStatsComponent,
    TeachersStatsComponent,
    ChangePasswordComponent,
    EditSchoolComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    AppRoutingModule
  ],
  providers: [Globals, {provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent],
})
export class AppModule { }
