import { Component, OnInit } from '@angular/core';
import { TeacherService } from 'app/teacher/teacher.service';

@Component({
  selector: 'app-permission-to-contact',
  templateUrl: './permission-to-contact.component.html',
  styleUrls: ['./permission-to-contact.component.css']
})
export class PermissionToContactComponent implements OnInit {

  answer: string;

  constructor(private teacherService: TeacherService) { }

  ngOnInit() {
  }

  yes() {

    this.teacherService.setCanContactForFeedback(true);
    this.answer = "yes";

  }

  no() {

    this.teacherService.setCanContactForFeedback(false);
    this.answer = "no";

  }

  hide() {

    this.answer = "hide";

  }

}
