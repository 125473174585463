import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import {Location} from '@angular/common';

import { TaskService }  from '../task/task.service';
import { Task } from 'app/task/task';
import { Globals } from '../globals';
import { UserService } from '../users/user.service';
import { TeacherService } from '../teacher/teacher.service';

import { saveAs } from 'file-saver/dist/FileSaver';

@Component({
  selector: 'app-view-results',
  templateUrl: './view-results.component.html',
  styleUrls: ['./view-results.component.css']
})
export class ViewResultsComponent implements OnInit {

  task: Task;
  gettingTask: boolean = false;

  resultsArray: any[];
  numJudgements: number;

  namesShowing: boolean = true;

  superAccess : boolean = false;

  constructor(
    private taskService: TaskService,
    private router: Router,
    private globals: Globals,
    private userService: UserService,
    private route: ActivatedRoute,
    private teacherService: TeacherService,
    private location: Location,
    ) { }

  ngOnInit() {

    var self = this;

    this.userService.loginLocalUserSession(this.route.snapshot.paramMap.get('school_id'), true, false, null, true, function(user) {

      // Get the expiry parameter if it exists (for super access) and ensure it hasn't elapsed ...

      var expiry = self.route.snapshot.paramMap.get('expiry');

      if (expiry != null) {

        if (self.isInt(expiry) && parseInt(expiry) > new Date().getTime()) {

          // Expiry exists and is in-date, so check against their user entry that they have super access
          // and that the expiry in the param matches the super_expiry in their entry

          self.teacherService
          .isSuperAccessExpiry(parseInt(expiry))
          .then((results: any) => {

            if (results.success == true) {

              // Continue to render the page as the expiry is valid ...

              self.superAccess = true;
              self.getTask();

            } else {

              self.router.navigate(['/home/', {}]);

            }

          });

        } else {

          self.router.navigate(['/home/', {}]);

        }

      } else {

        self.getTask();

      }

    });

  }

  isInt(n) {
    return n % 1 === 0;
  }

  getTask() {

    if (this.superAccess) {

      this.getResultsArray(true);

    } else if (this.route.snapshot.paramMap.get('expiry') == null) {

      this.getResultsArray(false);

    }

  }

  getResultsArray(isSuperAccess: boolean) {

    if (this.globals.user != null && this.globals.user.user_id != null && this.gettingTask == false) {

      this.gettingTask = true;

      const task_id: number = parseInt(this.route.snapshot.paramMap.get('task_id'));

      var userID = this.globals.user.user_id;
      if (isSuperAccess) {
        userID = this.route.snapshot.paramMap.get('teacher_id');
      }

      var schoolID = this.globals.user.school_id;
      if (isSuperAccess) {
        schoolID = this.route.snapshot.paramMap.get('school_id');
      }

      var superAccessTeacherID = null;
      if (isSuperAccess) {
        superAccessTeacherID = this.globals.user.user_id;
      }

      this.taskService
      .getTask(task_id, schoolID, userID)
      .then((task: Task) => {

        this.gettingTask = false;

        if (task != null && task.task_id == task_id) {

          this.task = task;

          this.teacherService
          .getResultsArray(schoolID, task.task_id)
          .then((resultsArray: any[]) => {

            this.resultsArray = resultsArray;
            this.numJudgements = resultsArray[0].num_judgements;

          });

        } else {

          if (this.globals.user) {
            this.goBack();
          } else {
            this.goHome();
          }
          
        }

      });

    }

  }

  onDownloadJudgementsClick(taskID: number) {

    var schoolID = this.globals.user.school_id;
    if (this.superAccess) {
      schoolID = this.route.snapshot.paramMap.get('school_id');
    }

    this.teacherService.getJudgementData(schoolID, taskID)
    .then((dataArray: any[]) => {

      const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
      const header = Object.keys(dataArray[0]);
      let csv = dataArray.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
      csv.unshift(header.join(','));
      let csvArray = csv.join('\r\n');
      var blob = new Blob([csvArray], {type: 'text/csv' })
      saveAs(blob, "judgements-task-" + taskID + ".csv");

    });

  }

  onViewJudgesClick(taskID: number) {

    var urlSuffix = "";
    var schoolID = this.globals.user.school_id;

    if (this.superAccess) {

      urlSuffix = this.route.snapshot.paramMap.get('teacher_id') + '/' + this.route.snapshot.paramMap.get('expiry') + '/';
      schoolID = this.route.snapshot.paramMap.get('school_id');

    }

    this.router.navigate(['/teacher/view_judges/' + schoolID + '/' + taskID + '/' + urlSuffix, {}]);

  }

  showHideNames() : void {

    if (this.namesShowing) {

      this.namesShowing = false;
      (<any>document.getElementById('show_hide_names_button')).innerHTML = "Show Names";

    } else {

      this.namesShowing = true;
      (<any>document.getElementById('show_hide_names_button')).innerHTML = "Hide Names";

    }

  }

  goBack(): void {

    var urlSuffix = "";
    var schoolID = this.globals.user.school_id;

    if (this.superAccess) {

      urlSuffix = this.route.snapshot.paramMap.get('teacher_id') + '/' + this.route.snapshot.paramMap.get('expiry') + '/';
      schoolID = this.route.snapshot.paramMap.get('school_id');

    }

    if (this.task && this.task.is_archived) {

      this.router.navigate(['/teacher/archived_tasks/' + schoolID + '/' + urlSuffix, {}]);

    } else {

      this.router.navigate(['/teacher/dashboard/' + schoolID + '/' + urlSuffix, {}]);

    }

  }

  goHome(): void {
    this.router.navigate(['/home/', {}]);
  }

}
