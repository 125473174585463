import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

import { Globals } from '../globals';
import { UserService } from '../users/user.service';

@Component({
  selector: 'app-top-nav-bar',
  templateUrl: './top-nav-bar.component.html',
  styleUrls: ['./top-nav-bar.component.css']
})
export class TopNavBarComponent implements OnInit {

  constructor(
    private router: Router,
    private globals: Globals,
    private userService: UserService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
  }

  onHomeClick(): void {

    if (this.globals.user == null) {

      this.router.navigate(['/home/']);

    } else if (this.globals.user.is_teacher == false) {

      if (this.globals.user && this.globals.user.school_id) {
        this.router.navigate(['/student/' + this.globals.user.school_id + '/', {}]);
      } else {
        this.router.navigate(['/home', {}]);  
      }

    } else if (this.globals.user.is_teacher == true) {

      if (this.globals.user && this.globals.user.school_id) {
        this.router.navigate(['/teacher/' + this.globals.user.school_id + '/', {}]);
      } else {
        this.router.navigate(['/home', {}]);  
      }

    }

  }

  onLogoutClick(): void {

    this.userService.logOutUser(this.route.snapshot.paramMap.get('school_id'));
    
  }

}
