export class Task {
    task_id: number;
    subject: string;
    class: string;
    title: string;
    teacher_id: string;
    teacher_name: string;
    school_id: string;
    submitted: boolean;
    can_submit: boolean;
    can_judge: boolean;
    num_work_items: number;
    num_judgements: number;
    min_time_to_judge_in_seconds: number;
    is_archived: boolean;
  }
