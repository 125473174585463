import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { acceptableNameValidator } from '../shared/acceptable-name.directive';
import { UserService } from '../users/user.service';
import { User } from '../users/user';
import { Globals } from '../globals';
import { SchoolService } from '../school/school.service';

@Component({
  selector: 'app-student-login',
  templateUrl: './student-login.component.html',
  styleUrls: ['./student-login.component.css']
})
export class StudentLoginComponent implements OnInit {

  loginForm: FormGroup;
  isSharedDevice: boolean;

  teacherLoginLink: string;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private globals: Globals,
    private schoolService: SchoolService,
    private router: Router,
  ) {}

  ngOnInit(): void {

    this.loginForm = new FormGroup({
      'name': new FormControl("", [
        Validators.required,
        acceptableNameValidator(/(\w.+\s).+/i)
    ])});

    // Set the isSharedDevice boolean
    this.isSharedDevice = this.userService.getIsSharedDevice();

    // Set the teacherLoginLink as the current student login link with the first instance of 'student' replaced with 'teacher'
    this.teacherLoginLink = '/#' + this.router.url.replace(/student/, "teacher");

    // Ensure the school_id parameter is a valid school, otherwise redirect to the teacher login as a student
    // requires a valid school_id set as a URL parameter to be logged in
    this.schoolService
    .isValidSchool(this.route.snapshot.paramMap.get('school_id'))
    .then((response: any) => {
      if (!response.success) {
        this.router.navigateByUrl('/teacher/login/' + encodeURIComponent(this.router.url));
      }
    });
    
  }

  sharedDeviceChange(event: any) {

    this.isSharedDevice = event.target.checked;

  }

  onSubmit() {

    // Get the name and ensure first letters of each word (name) and only first letters are capitalised
    var capitalisedUserName = this.toTitleCase(this.loginForm.value.name);

    this.userService.createUser(capitalisedUserName, this.isSharedDevice,
                                this.route.snapshot.paramMap.get('school_id'),
                                this.route.snapshot.paramMap.get('school_id'),
                                this.route.snapshot.paramMap.get('redirect_url'));

  }

  get name() { return this.loginForm.get('name'); }

  toTitleCase(str) {
    return str.replace(/\w\S*/g, function(txt){
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

}
