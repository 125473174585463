import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { TaskService }  from '../task/task.service';
import { Task } from 'app/task/task';
import { Globals } from '../globals';
import { UserService } from '../users/user.service';

@Component({
  selector: 'app-access-task',
  templateUrl: './access-task.component.html',
  styleUrls: ['./access-task.component.css']
})
export class AccessTaskComponent implements OnInit {

  accessTaskForm: FormGroup;

  task: Task;

  taskID: number;

  constructor(
    private route: ActivatedRoute,
    private taskService: TaskService,
    private router: Router,
    private globals: Globals,
    private userService: UserService,
  ) { }

  ngOnInit() {

    this.accessTaskForm = new FormGroup({
      'code': new FormControl("", [
        Validators.required])});

    var self = this;

    var taskIDParam = this.route.snapshot.paramMap.get('task_id');
    this.taskID = (taskIDParam != null && this.isInt(taskIDParam)) ? parseInt(taskIDParam) : -1;

    this.userService.loginLocalUserSession(this.route.snapshot.paramMap.get('school_id'), true, true, null, true, function(user) {

      self.getTask();

    });

  }

  isInt(n) {
    return n % 1 === 0;
  }

  get code() { return this.accessTaskForm.get('code'); }

  getTask() {

    if (this.globals.user != null && this.globals.user.user_id != null && this.task == null) {

      this.taskService
      .getTask(this.taskID, this.globals.user.school_id, this.globals.user.user_id)
      .then((task: Task) => {

        if (task != null && task.task_id == this.taskID && (task.can_submit || task.can_judge)) {

          this.task = task;

        } else {

          this.goBack();

        }

      });

    }

  }

  onSubmit() {

    this.taskService
      .getTaskWithAccessCode(
        this.task.task_id,
        this.globals.user.school_id,
        this.getAccessCodeAsFourCharacterString(this.accessTaskForm.value.code),
        this.globals.user.user_id)
      .then((task: Task) => {

        if (task != null && task.task_id == this.task.task_id) {

          if (document.getElementById('failed_message')) {
            document.getElementById('failed_message').innerHTML = "";
          }

          // Set the access_task_code variable in client storage (will persist, so not required to enter again for this log-in),
          // then navigate to the task
          this.userService.getIsSharedDevice() ? 
            sessionStorage.setItem("access_task_code_" + this.task.task_id,
              this.getAccessCodeAsFourCharacterString(this.accessTaskForm.value.code)) :
            localStorage.setItem("access_task_code_" + this.task.task_id,
            this.getAccessCodeAsFourCharacterString(this.accessTaskForm.value.code));

          this.userService.addTaskIDToAccessCodeArrayInClientStorage(this.task.task_id);

          this.router.navigateByUrl(this.route.snapshot.paramMap.get('redirect_url'));

        } else {
          if (document.getElementById('failed_message')) {
            document.getElementById('failed_message').innerHTML = "Wrong Code";
          } 
        }

      });

  }

  getAccessCodeAsFourCharacterString(accessCode: number): string {

    var accessCodeAsString = accessCode + "";

    while (accessCodeAsString.length < 4) {
      accessCodeAsString = "0" + accessCodeAsString;
    }

    return accessCodeAsString;

  }

  goBack(): void {

    if (this.globals.user && this.globals.user.school_id) {
      this.router.navigate(['/student/' + this.globals.user.school_id + '/', {}]);
    } else {
      this.router.navigate(['/home', {}]);  
    }
    
  }

}
