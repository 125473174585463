import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';

import * as toBlob from 'canvas-to-blob';

@Injectable({
  providedIn: 'root'
})

export class CompressorService {

  constructor() { }

  compress(file: File): Observable<any> {

    toBlob.init();
    if (toBlob.supported) {

      console.log("toBlob is supported");

      const width = 900; // For scaling relative to width
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return Observable.create(observer => {
        reader.onload = ev => {
          const img = new Image();
          img.src = (ev.target as any).result;
          (img.onload = () => {
            const elem = document.createElement('canvas'); // Use Angular's Renderer2 method
            const scaleFactor = width / img.width;
            elem.width = width;
            elem.height = img.height * scaleFactor;
            const ctx = <CanvasRenderingContext2D>elem.getContext('2d');
            ctx.drawImage(img, 0, 0, width, img.height * scaleFactor);
            (<HTMLCanvasElement>ctx.canvas).toBlob(
              blob => {
                observer.next(
                  this.blobToFile(blob, file.name),
                );
              },
              'image/jpeg',
              1,
            );
          }),
            (reader.onerror = error => observer.error(error));
        };
      });

    } else {

      console.log("toBlob is not supported");

      return Observable.create(observer => {
        observer.next(file);
      });
      
    }
  }

  public blobToFile = (theBlob: Blob, fileName:string): File => {

    var b: any = theBlob;
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;

    //Cast to a File() type
    return <File>theBlob;

  }

}