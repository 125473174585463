export class User {
    user_id: string;
    name: string;
    first_name: string;
    is_teacher: boolean;
    is_engaged_teacher: boolean = false;
    teacher_access_token: string;
    school_id: string;
    school_name: string;
    super_access: boolean;
    can_contact_for_feedback: boolean;
  }
