import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver/dist/FileSaver';

import { TeacherService } from '../teacher/teacher.service';
import { Globals } from '../globals';
import { UserService } from '../users/user.service';

@Component({
  selector: 'app-archived-tasks',
  templateUrl: './archived-tasks.component.html',
  styleUrls: ['./archived-tasks.component.css']
})
export class ArchivedTasksComponent implements OnInit {

  data : any[];
  updatingData : boolean = false;
  superAccess : boolean = false;

  constructor(
    private teacherService: TeacherService,
    private router: Router,
    private globals: Globals,
    private userService: UserService,
    private route: ActivatedRoute,
    ) { }

  ngOnInit() {

    var self = this;

    this.userService.loginLocalUserSession(this.route.snapshot.paramMap.get('school_id'), true, false, null, true, function(user) {

      // Get the expiry parameter if it exists (for super access) and ensure it hasn't elapsed ...

      var expiry = self.route.snapshot.paramMap.get('expiry');

      if (expiry != null) {

        if (self.isInt(expiry) && parseInt(expiry) > new Date().getTime()) {

          // Expiry exists and is in-date, so check against their user entry that they have super access
          // and that the expiry in the param matches the super_expiry in their entry

          self.teacherService
          .isSuperAccessExpiry(parseInt(expiry))
          .then((results: any) => {

            if (results.success == true) {

              // Continue to render the page as the expiry is valid ...

              self.superAccess = true;
              self.updateArchivedTasks();

            } else {

              self.router.navigate(['/home/', {}]);

            }

          });

        } else {

          self.router.navigate(['/home/', {}]);

        }

      } else {

        self.updateArchivedTasks();

      }

    });

  }

  updateArchivedTasks() {

    if (this.superAccess) {

      this.getArchivedTasks(true);

    } else if (this.route.snapshot.paramMap.get('expiry') == null) {

      this.getArchivedTasks(false);

    }

  }

  getArchivedTasks(isSuperAccess: boolean) {

    // Get data from the database

    if (this.globals.user != null && this.globals.user.user_id != null && this.data == null && this.globals.user.teacher_access_token != null && this.updatingData == false) {

      this.updatingData = true;

      var userID = this.globals.user.user_id;
      if (isSuperAccess) {
        userID = this.route.snapshot.paramMap.get('teacher_id');
      }

      var schoolID = this.globals.user.school_id;
      if (isSuperAccess) {
        schoolID = this.route.snapshot.paramMap.get('school_id');
      }

      var superAccessTeacherID = null;
      if (isSuperAccess) {
        superAccessTeacherID = this.globals.user.user_id;
      }

      this.teacherService
      .getDashboardData(
        userID,
        schoolID,
        this.globals.user.teacher_access_token,
        true,
        superAccessTeacherID)
      .then((dataArray: any[]) => {

        this.data = dataArray;
        this.updatingData = false;

      });

    } else if (this.globals.user == null && this.data != null) {

      this.data = null;

    }

  }

  isInt(n) {
    return n % 1 === 0;
  }

  onViewWorkClick(taskID: number) {

    var urlSuffix = "";
    var schoolID = "";

    if (this.superAccess) {

      urlSuffix = this.route.snapshot.paramMap.get('teacher_id') + '/' + this.route.snapshot.paramMap.get('expiry') + '/';
      schoolID = this.route.snapshot.paramMap.get('school_id');

    } else if (this.globals.user && this.globals.user.school_id) {

      schoolID = this.globals.user.school_id;

    } else {

      this.router.navigate(['/home/', {}]);

    }

    this.router.navigate(['/teacher/view_work/' + schoolID + '/' + taskID + '/' + urlSuffix, {}]);

  }

  onViewResultsClick(taskID: number) {

    var urlSuffix = "";
    var schoolID = "";

    if (this.superAccess) {

      urlSuffix = this.route.snapshot.paramMap.get('teacher_id') + '/' + this.route.snapshot.paramMap.get('expiry') + '/';
      schoolID = this.route.snapshot.paramMap.get('school_id');

    } else if (this.globals.user && this.globals.user.school_id) {

      schoolID = this.globals.user.school_id;

    } else {

      this.router.navigate(['/home/', {}]);

    }

    this.router.navigate(['/teacher/view_results/' + schoolID + '/' + taskID + '/' + urlSuffix, {}]);

  }

  onUnarchiveTask(taskID: number) {

    this.teacherService
      .archiveTask(this.globals.user.school_id, taskID, false)
      .then((results: any[]) => {

        // Refresh the list of archived tasks by fetching the tasks again
        this.data = null;
        this.updateArchivedTasks();

      });
    
  }

  onDeleteTask(taskID: number, taskSubject: string, taskClass: string, taskTitle: string) {

    var confirmDelete = confirm(
      "Are you sure you want to delete task '" + taskTitle + " (" + taskSubject + " - " + taskClass +
        ")'?  This action CAN'T BE UNDONE!");

    if (confirmDelete) {

      this.teacherService
      .deleteTask(this.globals.user.school_id, taskID)
      .then((results: any[]) => {

        // Refresh the archived tasks by fetching the tasks again
        this.data = null;
        this.updateArchivedTasks();

      });

    } else {

      // Do nothing

    }

  }

  goBack(): void {

    if (this.superAccess) {

      this.router.navigate(['/teacher/dashboard/' +
      this.route.snapshot.paramMap.get('school_id') + '/' +
      this.route.snapshot.paramMap.get('teacher_id') + '/' +
      this.route.snapshot.paramMap.get('expiry'), {}]);

    } else {

      this.router.navigate(['/teacher/dashboard/' + this.globals.user.school_id + '/', {}]);

    }

  }

  goHome(): void {
    this.router.navigate(['/home/', {}]);
  }

}
