import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { UserService } from '../users/user.service';
import { TeacherService } from '../teacher/teacher.service';
import { SchoolService } from '../school/school.service';

@Component({
  selector: 'app-teacher-login',
  templateUrl: './teacher-login.component.html',
  styleUrls: ['./teacher-login.component.css']
})
export class TeacherLoginComponent implements OnInit {

  loginForm: FormGroup;
  isSharedDevice: boolean;

  invalidEmail: boolean = false;
  invalidPassword: boolean = false;
  formSubmissionError: string;

  studentLoginLink: string;

  constructor(
    private userService: UserService,
    private teacherService: TeacherService,
    private route: ActivatedRoute,
    private router: Router,
    private schoolService: SchoolService,
  ) { }

  ngOnInit() {

    this.loginForm = new FormGroup({});

    // Set the isSharedDevice boolean
    this.isSharedDevice = this.userService.getIsSharedDevice();

    // If the redirect_url parameter does not have the substring 'teacher' in it
    // And if the school_id parameter is valid
    // Then show a link to login as a student (as an option)

    if (this.route.snapshot.paramMap.get('redirect_url').includes('teacher') == false) {

      this.schoolService
      .isValidSchool(this.route.snapshot.paramMap.get('school_id'))
      .then((response: any) => {
        if (response.success) {

          // Replace the first occurence of 'teacher' with 'student' in the login url and set this.studentLoginLink to this
          this.studentLoginLink = '/#' + this.router.url.replace(/teacher/, "student");

        }
      });

    }

  }

  sharedDeviceChange(event: any) {

    this.isSharedDevice = event.target.checked;

  }

  onShowHidePassword(): void {

    var passwordElement : HTMLInputElement = <HTMLInputElement>document.getElementById('password');
    var showHideButton : HTMLButtonElement = <HTMLButtonElement>document.getElementById('showHideButton');

    if (passwordElement.type == "password") {

      passwordElement.type = "text";
      showHideButton.innerText = "Hide";

    } else if (passwordElement.type = "text") {

      passwordElement.type = "password";
      showHideButton.innerText = "Show";

    }

  }

  onSubmit() {

    // Get all the attributes ...

    var email = (<HTMLInputElement>document.getElementById('email')).value;
    var password = (<HTMLInputElement>document.getElementById('password')).value;

    // Validate all the inputs first and return an error message if any are invalid / incomplete ...

    var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (email == null || email == "" || !emailRegex.test(email) ) {
      this.invalidEmail = true;
    } else {
      this.invalidEmail = false;
    }

    if (password == null || password == "") {
      this.invalidPassword = true;
    } else {
      this.invalidPassword = false;
    }

    this.formSubmissionError = null;
    
    if (!this.invalidEmail && !this.invalidPassword) {

      // Input is valid, so try to log the user in

      var self = this;

      this.teacherService
      .teacherLogin(
        email,
        password,
        this.isSharedDevice
      )
      .then((response: any) => {

        if (response != null && response.user_id) {

          // Set the user_id in client storage and the user and school_id in globals, then redirect to the redirect_url
          this.userService.saveUserID(response.user_id);
          this.userService.loginLocalUserSession(
            this.route.snapshot.paramMap.get('school_id'),
            false,
            false,
            this.route.snapshot.paramMap.get('redirect_url'),
            true,
            function(user) {});

        } else if (response != null && response.error != null) {

          this.formSubmissionError = response.error;

        } else {
 
          this.formSubmissionError = "An unknown error occurred when trying to login";

        }

      });

    }

  }

}
