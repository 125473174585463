import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { Task } from '../task/task';
import { TeacherService } from '../teacher/teacher.service';
import { Globals } from '../globals';
import { UserService } from '../users/user.service';
import { SchoolService } from '../school/school.service';

@Component({
  selector: 'app-teacher-dashboard',
  templateUrl: './teacher-dashboard.component.html',
  styleUrls: ['./teacher-dashboard.component.css']
})
export class TeacherDashboardComponent implements OnInit {

  data : any[];
  updatingData : boolean = false;
  superAccess : boolean = false;
  cameFromAllTeachersPage: boolean = false;

  passwordChangeApprovalFailed: boolean = false;

  schoolID;

  constructor(
    private route: ActivatedRoute,
    private teacherService: TeacherService,
    private location: Location,
    private router: Router,
    private globals: Globals,
    private userService: UserService,
    private schoolService: SchoolService,
    ) { }

  ngOnInit() {

    var self = this;

    this.userService.loginLocalUserSession(this.route.snapshot.paramMap.get('school_id'), true, false, null, true, function(user) {

      // Get the expiry parameter if it exists (for super access) and ensure it hasn't elapsed ...

      var expiry = self.route.snapshot.paramMap.get('expiry');

      if (expiry != null) {

        if (self.isInt(expiry) && parseInt(expiry) > new Date().getTime()) {

          // Expiry exists and is in-date, so check against their user entry that they have super access
          // and that the expiry in the param matches the super_expiry in their entry

          self.teacherService
          .isSuperAccessExpiry(parseInt(expiry))
          .then((results: any) => {

            if (results.success == true) {

              // Continue to render the page as the expiry is valid (and get the source param if there is one) ...

              self.route.queryParams.subscribe(params => {
                // Defaults to false if no query param provided
                self.cameFromAllTeachersPage = (params['source'] == "teachers") || false;
              });

              self.superAccess = true;
              self.updateDashboard();

            } else {

              self.router.navigate(['/home/', {}]);

            }

          });

        } else {

          self.router.navigate(['/home/', {}]);

        }

      } else {

        self.updateDashboard();

      }

    });   

  }

  updateDashboard() {

    if (this.superAccess) {

      this.getDashboardData(true);
      this.schoolID = this.route.snapshot.paramMap.get('school_id');

    } else if (this.route.snapshot.paramMap.get('expiry') == null) {

      this.getDashboardData(false);
      this.schoolID = this.globals.user.school_id;

    }

  }

  getDashboardData(isSuperAccess: boolean) {

    // Get data from the database

    if (this.globals.user != null && this.globals.user.user_id != null && this.data == null && this.globals.user.teacher_access_token != null && this.updatingData == false) {

      this.updatingData = true;

      var userID = this.globals.user.user_id;
      if (isSuperAccess) {
        userID = this.route.snapshot.paramMap.get('teacher_id');
      }

      var schoolID = this.globals.user.school_id;
      if (isSuperAccess) {
        schoolID = this.route.snapshot.paramMap.get('school_id');
      }

      var superAccessTeacherID = null;
      if (isSuperAccess) {
        superAccessTeacherID = this.globals.user.user_id;
      }

      this.teacherService
      .getDashboardData(
        userID,
        schoolID,
        this.globals.user.teacher_access_token,
        false,
        superAccessTeacherID)
      .then((dataArray: any[]) => {

        this.data = dataArray;
        this.updatingData = false;

      });

    } else if (this.globals.user == null && this.data != null) {

      this.data = null;

    }

  }

  isInt(n) {
    return n % 1 === 0;
  }

  goToArchivedTasks() {

    if (this.superAccess) {

      this.router.navigate(['/teacher/archived_tasks/' +
      this.route.snapshot.paramMap.get('school_id') + '/' +
      this.route.snapshot.paramMap.get('teacher_id') + '/' +
      this.route.snapshot.paramMap.get('expiry'), {}]);

    } else {

      this.router.navigate(['/teacher/archived_tasks/' + this.globals.user.school_id + '/', {}]);

    }

  }

  createNewTaskClicked() {
    this.router.navigate(['/teacher/create_new_task/' + this.globals.user.school_id + '/', {}]);
  }

  canSubmitChanged(event: any, task: Task) {

    task.can_submit = event.target.checked;

    if (task.can_submit) {
      // If it is now checked, make sure that can_judge is unchecked
      task.can_judge = false;
      this.teacherService.updateCanJudgeForTask(this.globals.user.school_id, task.task_id, false);
    }

    
    this.teacherService.updateCanSubmitForTask(this.globals.user.school_id, task.task_id, task.can_submit);

  }

  onViewWorkClick(taskID: number) {

    var urlSuffix = "";
    var schoolID = "";

    if (this.superAccess) {

      urlSuffix = this.route.snapshot.paramMap.get('teacher_id') + '/' + this.route.snapshot.paramMap.get('expiry') + '/';
      schoolID = this.route.snapshot.paramMap.get('school_id');

    } else if (this.globals.user && this.globals.user.school_id) {

      schoolID = this.globals.user.school_id;

    } else {

      this.router.navigate(['/home/', {}]);

    }

    this.router.navigate(['/teacher/view_work/' + schoolID + '/' + taskID + '/' + urlSuffix, {}]);

  }

  canJudgeChanged(event: any, task: Task) {

    // We'll only update task.can_judge after the teacher has checked all student work
    var canJudge = event.target.checked;

    if (canJudge) {

      // Stop the checkbox from being checked (this will be done later after the teacher has checked the student work)
      event.preventDefault();
      event.stopPropagation();

      // Then show an alert to tell the teacher to screen submitted work and delete inappropriate entries
      // before proceeding to judge phase
      if (task.num_work_items < 2) {

        alert("At least two work items need to be submitted before you can move into the judge phase");

      } else {

        // If an attempt has been made to move into the judgement phase, make sure that can_submit is unchecked
        task.can_submit = false;
        this.teacherService.updateCanSubmitForTask(this.globals.user.school_id, task.task_id, false);

        // Pause briefly so the UI updates with the can_submit toggle disabled
        setTimeout(() => {
          alert("Please screen the student's submitted work and delete any inappropriate entries before proceeding to the judge phase");
          this.router.navigate(['/teacher/view_work/' + this.globals.user.school_id + '/' 
            + task.task_id], { queryParams: { screen_work: true } } );
        },400);

      }

    } else {

      task.can_judge = false;
      this.teacherService.updateCanJudgeForTask(this.globals.user.school_id, task.task_id, false);

    }

  }

  decrement(task: Task) {

    const inputElement: HTMLInputElement = <HTMLInputElement> document.getElementById("minTimeToJudgeInputForTask" + task.task_id);
    var newValue = Math.max((parseInt(inputElement.value) - 1), 0);
    inputElement.value = "" + newValue;

    this.updateMinTimeToJudge(newValue, task);

  }

  increment(task: Task) {

    const inputElement: HTMLInputElement = <HTMLInputElement> document.getElementById("minTimeToJudgeInputForTask" + task.task_id);
    var newValue = Math.min((parseInt(inputElement.value) + 1), 120);
    inputElement.value = "" + newValue;

    this.updateMinTimeToJudge(newValue, task);
    
  }

  updateMinTimeToJudgeFromInput(event: any, task: Task) {

    this.updateMinTimeToJudge(parseInt(event.target.value), task);

  }

  updateMinTimeToJudge(value: number, task: Task) {

    if (value != null) {

      this.teacherService.updateMinTimeToJudgeTask(this.globals.user.school_id, task.task_id, value);

    }

  }

  onViewResultsClick(taskID: number) {

    var urlSuffix = "";
    var schoolID = "";

    if (this.superAccess) {

      urlSuffix = this.route.snapshot.paramMap.get('teacher_id') + '/' + this.route.snapshot.paramMap.get('expiry') + '/';
      schoolID = this.route.snapshot.paramMap.get('school_id');

    } else if (this.globals.user && this.globals.user.school_id) {

      schoolID = this.globals.user.school_id;

    } else {

      this.router.navigate(['/home/', {}]);

    }

    this.router.navigate(['/teacher/view_results/' + schoolID + '/' + taskID + '/' + urlSuffix, {}]);

  }

  onArchiveTask(taskID: number) {

    this.teacherService
      .archiveTask(this.globals.user.school_id, taskID, true)
      .then((results: any[]) => {

        // Refresh the dashboard by fetching the tasks again
        this.data = null;
        this.updateDashboard();

      });
    
  }

  onDeleteTask(taskID: number, taskSubject: string, taskClass: string, taskTitle: string) {

    var confirmDelete = confirm(
      "Are you sure you want to delete task '" + taskTitle + " (" + taskSubject + " - " + taskClass +
        ")'?  This action CAN'T BE UNDONE!");

    if (confirmDelete) {

      this.teacherService
      .deleteTask(this.globals.user.school_id, taskID)
      .then((results: any[]) => {

        // Refresh the dashboard by fetching the tasks again
        this.data = null;
        this.updateDashboard();

      });

    } else {

      // Do nothing

    }

  }

  copyStudentTaskLink(data: any, task_id: number) {

    var schoolID = this.globals.user.school_id;

    if (this.superAccess) {
      schoolID = this.route.snapshot.paramMap.get('school_id');
    }

    this.copyToClipboard("https://app.compareandlearn.com/#/task/" + schoolID + "/" + task_id, function() {

      for (var i=1; i<data.tasks.length; i+=2) {

        for (var j=0; j<data.tasks[i].tasks.length; j++) {

          var copyButton = document.getElementById("copy_button_" + data.tasks[i].tasks[j].task_id);

          copyButton.classList.remove("btn-default");
          copyButton.classList.remove("btn-primary");

          if (data.tasks[i].tasks[j].task_id == task_id) {

            copyButton.innerHTML = "Copied";
            copyButton.classList.add("btn-primary");

          } else {

            copyButton.innerHTML = "Copy";
            copyButton.classList.add("btn-default");

          }

        }

      }
      
    });

  }

  copyToClipboard(str, callback) {

    const el = document.createElement('textarea');  // Create a <textarea> element
    el.value = str;                                 // Set its value to the string that you want copied
    el.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
    el.style.position = 'absolute';                 
    el.style.left = '-9999px';                      // Move outside the screen to make it invisible
    document.body.appendChild(el);                  // Append the <textarea> element to the HTML document
    const selected =            
      document.getSelection().rangeCount > 0        // Check if there is any content selected previously
        ? document.getSelection().getRangeAt(0)     // Store selection if found
        : false;                                    // Mark as false to know no selection existed before
    el.select();                                    // Select the <textarea> content
    document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
    document.body.removeChild(el);                  // Remove the <textarea> element
    if (selected) {                                 // If a selection existed before copying
      document.getSelection().removeAllRanges();    // Unselect everything on the HTML document
      document.getSelection().addRange(selected);   // Restore the original selection
    }

    if (callback) {
      callback();
    }

  }

  getQRCode(data: any, task_id: number) {

    var schoolID = this.globals.user.school_id;
    
    if (this.superAccess) {
      schoolID = this.route.snapshot.paramMap.get('school_id');
    }

    var link = "https://chart.googleapis.com/chart?chs=400x400&cht=qr&chl=" +
      encodeURIComponent("https://app.compareandlearn.com/#/task/" + schoolID + "/" + task_id);

    window.location.href = link;

  }

  editSchool() {

    this.router.navigate(['/teacher/edit/school/' +
          this.route.snapshot.paramMap.get('school_id') + '/' +
          this.route.snapshot.paramMap.get('teacher_id') + '/' +
          this.route.snapshot.paramMap.get('expiry'), {}]);

  }

  approvePasswordChangeRequest(passwordChangeTeacherID: string, approve: boolean) {

    var self = this;

    this.teacherService
    .approvePasswordChange(passwordChangeTeacherID, approve)
    .then((results: any) => {

      if (results.success == true) {

        self.passwordChangeApprovalFailed = false;

        // Refresh the page by getting the data again
        self.data = null;
        self.updateDashboard();

      } else {

        self.passwordChangeApprovalFailed = true;

      }

    });

  }

  goBack(): void {

    if (this.superAccess) {

      if (this.cameFromAllTeachersPage ) {

        this.router.navigate(['/teacher/stats/teachers/' + this.route.snapshot.paramMap.get('expiry'), {}]);

      } else {

        this.router.navigate(['/teacher/stats/teachers/' +
          this.route.snapshot.paramMap.get('school_id') + '/' +
          this.route.snapshot.paramMap.get('expiry'), {}]);

      }

    } else {

      this.router.navigate(['/home', {}]);

    }

  }

  goHome(): void {
    this.router.navigate(['/home/', {}]);
  }

}
