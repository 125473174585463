import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
 
import { TeacherComponent }   from './teacher/teacher.component';
import { StudentComponent }   from './student/student.component';
import { TeacherLoginComponent }   from './teacher-login/teacher-login.component';
import { StudentLoginComponent }   from './student-login/student-login.component';
import { TaskComponent }      from './task/task.component';
import { SubmitComponent }      from './submit/submit.component';
import { JudgeComponent }      from './judge/judge.component';
import { AccessTaskComponent }      from './access-task/access-task.component';
import { TeacherDashboardComponent }      from './teacher-dashboard/teacher-dashboard.component';
import { CreateNewTaskComponent }      from './create-new-task/create-new-task.component';
import { ArchivedTasksComponent }      from './archived-tasks/archived-tasks.component';
import { ViewWorkComponent }      from './view-work/view-work.component';
import { ViewResultsComponent }      from './view-results/view-results.component';
import { ViewJudgesComponent }    from './view-judges/view-judges.component';
import { InviteComponent }    from './invite/invite.component';
import { SendInviteComponent }    from './send-invite/send-invite.component';
import { SchoolsStatsComponent }    from './schools-stats/schools-stats.component';
import { TeachersStatsComponent }    from './teachers-stats/teachers-stats.component';
import { ChangePasswordComponent }    from './change-password/change-password.component';
import { EditSchoolComponent }    from './edit-school/edit-school.component';
 
const routes: Routes = [

  { path: '', redirectTo: '/teacher', pathMatch: 'full' },

  { path: 'home', redirectTo: '/teacher', pathMatch: 'full' },

  { path: 'teacher', component: TeacherComponent },
  { path: 'teacher/:school_id', component: TeacherComponent },

  { path: 'student/:school_id', component: StudentComponent },

  { path: 'teacher/login/:redirect_url', component: TeacherLoginComponent },
  { path: 'teacher/login/:school_id/:redirect_url', component: TeacherLoginComponent },
  { path: 'student/login/:redirect_url', component: StudentLoginComponent },
  { path: 'student/login/:school_id/:redirect_url', component: StudentLoginComponent },

  { path: 'task/:school_id/:task_id', component: TaskComponent },
  { path: 'task/submit/:school_id/:task_id', component: SubmitComponent },
  { path: 'task/judge/:school_id/:task_id', component: JudgeComponent },
  { path: 'access-task/:school_id/:task_id/:redirect_url', component: AccessTaskComponent },

  { path: 'teacher/dashboard/:school_id', component: TeacherDashboardComponent },
  { path: 'teacher/create_new_task/:school_id', component: CreateNewTaskComponent },
  { path: 'teacher/archived_tasks/:school_id', component: ArchivedTasksComponent },
  { path: 'teacher/view_work/:school_id/:task_id', component: ViewWorkComponent },
  { path: 'teacher/view_results/:school_id/:task_id', component: ViewResultsComponent },
  { path: 'teacher/view_judges/:school_id/:task_id', component: ViewJudgesComponent },

  // Super access only
  { path: 'teacher/dashboard/:school_id/:teacher_id/:expiry', component: TeacherDashboardComponent },
  { path: 'teacher/archived_tasks/:school_id/:teacher_id/:expiry', component: ArchivedTasksComponent },
  { path: 'teacher/view_work/:school_id/:task_id/:teacher_id/:expiry', component: ViewWorkComponent },
  { path: 'teacher/view_results/:school_id/:task_id/:teacher_id/:expiry', component: ViewResultsComponent },
  { path: 'teacher/view_judges/:school_id/:task_id/:teacher_id/:expiry', component: ViewJudgesComponent },

  { path: 'invite/:invite_id', component: InviteComponent },
  { path: 'teacher/invite/:school_id', component: SendInviteComponent },

  { path: 'teacher/stats/schools/:expiry', component: SchoolsStatsComponent },
  { path: 'teacher/stats/teachers/:expiry', component: TeachersStatsComponent },
  { path: 'teacher/stats/teachers/:school_id/:expiry', component: TeachersStatsComponent },

  { path: 'teacher/edit/school/:school_id/:teacher_id/:expiry', component: EditSchoolComponent },

  { path: 'teacher/account/change-password', component: ChangePasswordComponent },

  { path: '**', redirectTo: '/teacher', pathMatch: 'full' }
  
];
 
@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
