import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ViewChild } from '@angular/core';

import { map, expand } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { CompressorService } from './compressor.service';

import { TaskService }  from '../task/task.service';
import { Task } from 'app/task/task';
import { WorkService }  from '../work/work.service';
import { Globals } from '../globals';
import { UserService } from '../users/user.service';

import * as exifJS from 'exif-js';
import * as jpegAutorotate from '../jpeg-autorotate';

import * as buffer from 'buffer';

@Component({
  selector: 'app-submit',
  templateUrl: './submit.component.html',
  styleUrls: ['./submit.component.css']
})
export class SubmitComponent implements OnInit {

  @ViewChild('videoElement') videoElement: any;  
  video: any;

  task: Task;
  image: any;
  processing: boolean = false;
  rotating: boolean = false;
  compressing: boolean = false;
  uploading: boolean = false;
  studentNameRequiredOnSubmit: boolean = false;

  userIDToSaveWorkAs: string;
  userNameToSaveWorkAs: string;
  teacherIDWhoSubmittedWork: string = null;

  taskID: number;
  accessTaskURL : string;

  data: FileList;
  compressedImages = [];
  timestampForLastUpload: number;

  constructor(
    private route: ActivatedRoute,
    private taskService: TaskService,
    private workService: WorkService,
    private router: Router,
    private globals: Globals,
    private compressor: CompressorService,
    private userService: UserService
  ) { }

  ngOnInit() {

    var self = this;

    var taskIDParam = this.route.snapshot.paramMap.get('task_id');
    this.taskID = (taskIDParam != null && this.isInt(taskIDParam)) ? parseInt(taskIDParam) : -1;

    this.userService.loginLocalUserSession(this.route.snapshot.paramMap.get('school_id'), true, true, null, true, function(user) {

      self.accessTaskURL = '/access-task/' + self.globals.user.school_id + '/' + self.taskID + '/' + encodeURIComponent(self.router.url);

      self.userService.getIsSharedDevice() ? 
        self.getTask(sessionStorage.getItem("access_task_code_" + self.route.snapshot.paramMap.get('task_id'))) :
        self.getTask(localStorage.getItem("access_task_code_" + self.route.snapshot.paramMap.get('task_id')));

    });

  }

  isInt(n) {
    return n % 1 === 0;
  }

  getTask(code: string) {

    if (this.globals.user != null && this.globals.user.user_id != null) {
      
      this.taskService
      .getTaskWithAccessCode(this.taskID, this.globals.user.school_id, code, this.globals.user.user_id)
      .then((task: Task) => {

        if (task != null && task.task_id == this.taskID) {

          if (!task.submitted && task.can_submit) {

            this.task = task;
            this.updateView();

          } else {

            this.router.navigate(['/task/' + this.globals.user.school_id + '/' + this.taskID, {}]);

          }

        } else {

          this.router.navigateByUrl(this.accessTaskURL);

        }

      });

    } else if (this.globals.user == null && this.task != null) {

      this.task = null;
      this.router.navigateByUrl(this.accessTaskURL);

    }

  }

  updateView() {

    if (this.globals.user == null) {

      const capturedImageElement: any = document.getElementById('captured_image');

      if (capturedImageElement != null) {
        capturedImageElement.src = "";
      }

      this.image = null;
      this.compressedImages = [];

      this.task = null;

    } else {

      if (this.task != null) {

        this.instantiateFileEventListener();

      }

    }

  }

  instantiateFileEventListener() {

    var self = this;

    const files: any = document.getElementById('files');

    files.addEventListener('change', (e) => {

      this.processing = true;
      console.log("153. this.processing = " + this.processing);

      const capturedImageElement: any = document.getElementById('captured_image');

      capturedImageElement.style.display = "none";
      document.getElementById('image_br').style.display = "none";
      document.getElementById('image_br2').style.display = "none";

      // Pause for 0.5 seconds to let the UI update before the intensive
      // job of processing the photo
      
      setTimeout(() => {

        console.log("captured files: " + e.target.files);

        var fileList: FileList = e.target.files;
        var newImage = false;

        for (let i = 0; i < fileList.length; i++) {
          if (fileList[i].type.match(/^image\//)) {
            this.image = fileList[i];
            newImage = true;
            break;
          }
        }

        if (this.image != null && newImage) {

          console.log("url = " + URL.createObjectURL(this.image));

          exifJS.getData(this.image, function() {

            var allMetaData = exifJS.getAllTags(this);
      
            if (Object.keys(allMetaData).length > 0 && allMetaData["Orientation"] &&
                (allMetaData["Orientation"] != 1 || allMetaData["Orientation"] != "1")) {
      
              // It is an iOS device (as it has EXIF parameters inc. an orientation tag), so rotate it appropriately

              self.rotating = true;

              // Pause for 0.5 seconds to let the UI update before the intensive
              // job of processing the photo
              
              setTimeout(() => {
      
                var fileReader = new FileReader();
                fileReader.onload = function() {

                  var arrayBuffer = fileReader.result;
                  var newBuffer = self.toBuffer(arrayBuffer);

                  jpegAutorotate.rotate(newBuffer, {}, function(error, buffer, orientation) {

                    if (error) {
                        console.log('An error occurred when rotating the file: ' + error.message);
                        return;
                    }
                    console.log('Orientation was: ' + orientation);

                    var myBlob = new Blob([buffer]);
                    // var blobUrl = URL.createObjectURL(blob);
                    // saveAs(blobUrl, "image.jpeg");

                    var myFile : File = self.blobToFile(myBlob, "my-image.jpeg");

                    self.rotating = false;

                    self.process([myFile]);

                  });

                };
                fileReader.readAsArrayBuffer(self.image);

              }, 500);
              
            } else {
      
              // Else it is not an iOS, so don't rotate it
              self.process([self.image]);
      
            }

          });

        }

      },500);

    });

  }

  blobToFile = (theBlob: Blob, fileName:string): File => {
    var b: any = theBlob;
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;

    //Cast to a File() type
    return <File>theBlob;
}

  toBuffer(ab) {
    var buf = buffer.Buffer.alloc(ab.byteLength);
    var view = new Uint8Array(ab);
    for (var i = 0; i < buf.length; ++i) {
        buf[i] = view[i];
    }
    return buf;
}

  recursiveCompress = (image: File, index, array) => {
    return this.compressor.compress(image).pipe (
      map(response => {
      //Code block after completing each compression
        console.log('compressed ' + index + image.name);
        this.compressedImages.push(response);
        return {
          data: response,
          index: index + 1,
          array: array,
        };
      }),
    );
  }

  //process files for upload
  process(files) {

    var self = this;
    this.processing = true;
    console.log("286. this.processing = " + this.processing);
    this.compressing = true;
    console.log("288. this.compressing = " + this.compressing);

    // Pause for 0.5 seconds to let the UI update before the intensive
      // job of processing the photo
      
    setTimeout(() => {

      const compress = this.recursiveCompress( files[0], 0, files ).pipe(
        expand(res => {
          return res.index > res.array.length - 1
            ? EMPTY
            : this.recursiveCompress( files[res.index], res.index, files );
        }),
      );

      compress.subscribe(res => {

        if (res.index > res.array.length - 1) {

          // Code block after completing all compression

          console.log('Compression successful ' + this.compressedImages);
          var lastCompressedImage = this.compressedImages[this.compressedImages.length-1];
          var lastCompressedImageURL = URL.createObjectURL(lastCompressedImage);
          console.log("compressed image url = " + lastCompressedImageURL);

          this.processing = false;
          console.log("313. this.processing = " + this.processing);
          this.compressing = false;
          console.log("315. this.compressing = " + this.compressing);

          const capturedImageElement: any = document.getElementById('captured_image');

          capturedImageElement.style.display = "block";
          document.getElementById('image_br').style.display = "block";
          document.getElementById('image_br2').style.display = "block";

          var imageURL = URL.createObjectURL(this.image);
          console.log("image url = " + imageURL);
          capturedImageElement.src = lastCompressedImageURL;

          // var blob = new Blob([imageURL], {type: 'image/jpeg' })
          // var blobUrl = URL.createObjectURL(blob);
          // saveAs(imageURL, "image.jpeg");

        }

      });

    }, 500);

  }


  onTaskSubmit() {

    // If the logged-in user is the teacher who created the task, check they have entered a student name first

    if (this.task.teacher_id == this.globals.user.user_id) {

      var studentNameInputElement: HTMLInputElement = <HTMLInputElement> document.getElementById("student_name");
      if (studentNameInputElement.value == null || studentNameInputElement.value == "") {
        this.studentNameRequiredOnSubmit = true;
        return;
      }

    }

    // Check can_submit is still true
    this.taskService
      .getTask(this.task.task_id, this.globals.user.school_id, this.globals.user.user_id)
      .then((task: Task) => {

        if (task.can_submit) {

          // Change button text temporarily during upload and disable
          this.setUploading(true);

          // If the logged-in user is the teacher who created the task, create a new user for them to submit as

          if (this.task.teacher_id == this.globals.user.user_id) {

            var self = this;

            var capitalisedUserName = this.toTitleCase((<HTMLInputElement> document.getElementById("student_name")).value);

            this.userService
            .createUserAsTeacher(
              capitalisedUserName,
              this.globals.user.school_id,
              this.task.teacher_id)
            .then((results: any) => {

              if (results.user_id && results.name && results.created_by_teacher) {

                this.userIDToSaveWorkAs = results.user_id;
                this.userNameToSaveWorkAs = results.name;
                this.teacherIDWhoSubmittedWork = results.created_by_teacher;
                self.saveFileToS3(task);

              } else {

                // If it fails here, log an error and show the error to the user
                alert("Failed to save this student's work");
                console.error("Failed to save this student's work");

              }

            });

          } else {

            this.userIDToSaveWorkAs = this.globals.user.user_id;
            this.userNameToSaveWorkAs = this.globals.user.name;
            this.saveFileToS3(task);

          }

        } else {

          alert("Task not submitted because the submission has been closed by the teacher");
          this.router.navigate(['/task/' + this.globals.user.school_id + 
            '/' + parseInt(this.route.snapshot.paramMap.get('task_id')), {}]);

        }
      }
    );

  }

  saveFileToS3(task: Task) {

    // Save the latest compressed file to S3
    var latestCompressedFile = this.compressedImages[this.compressedImages.length-1];
    this.timestampForLastUpload = new Date().getTime();

    // Upload to S3
    this.getSignedRequestForPut(
      latestCompressedFile,
      this.userIDToSaveWorkAs,
      this.globals.user.school_id,
      task.teacher_id,
      this.timestampForLastUpload);

  }


  setUploading(uploading: boolean) {

    const submitButton = document.getElementById('submit');
    const captureButtonLabel = document.getElementById('capture');

    if (uploading) {

      // Change button text temporarily during upload and disable
      this.uploading = true;
      submitButton.innerHTML = "Uploading ...";
      submitButton.className = "btn";
      captureButtonLabel.className = "btn disabled";

    } else {

      // Change the button text back and enable
      this.uploading = false;
      submitButton.innerHTML = "Submit";
      submitButton.className = "btn btn-success";
      captureButtonLabel.className = "btn btn-success";

    }
    
  }

  getSignedRequestForPut(file, userID, schoolID, teacherID, timestamp) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', `/sign-s3-put?file-name=${file.name}&file-type=${file.type}&file-size=${file.size}&user_id=${userID}&school_id=${schoolID}&teacher_id=${teacherID}&timestamp=${timestamp}`);
    xhr.onreadystatechange = () => {
      if(xhr.readyState === 4){
        if(xhr.status === 200){
          const response = JSON.parse(xhr.responseText);
          if (response.error) {
            this.setUploading(false);
            alert(response.error);
          } else {
            this.uploadFile(file, response.signedRequest, response.url);
          }
        }
        else{
          alert('Could not get signed URL.');
        }
      }
    };
    xhr.send();
  }

  getSignedRequestForGet(userID, schoolID, teacherID, timestamp, folderName){
    const xhr = new XMLHttpRequest();
    xhr.open('GET', `/sign-s3-get?user-id=${userID}&school_id=${schoolID}&teacher_id=${teacherID}&timestamp=${timestamp}`);
    xhr.onreadystatechange = () => {
      if(xhr.readyState === 4){
        if(xhr.status === 200){
          const response = JSON.parse(xhr.responseText);
          if (response.error) {
            alert(response.error);
          } else {
            console.log("response.signedRequest = " + response.signedRequest);
          }
        }
        else{
          alert('Could not get signed URL.');
        }
      }
    };
    xhr.send();
  }

  uploadFile(file, signedRequest, url){
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', signedRequest);
    xhr.setRequestHeader("Content-Type", file.type);
    // xhr.setRequestHeader("x-amz-acl", 'public-read');
    xhr.onreadystatechange = () => {
      if(xhr.readyState === 4){
        if(xhr.status === 200){

          console.log("image uploaded successfully");

          // Now add in a new document to the work collection, and then redirect back to the task submission list

          this.workService
            .createWork(
              this.task.task_id,
              this.task.teacher_id,
              this.userIDToSaveWorkAs,
              this.userNameToSaveWorkAs,
              this.globals.user.school_id,
              this.timestampForLastUpload,
              file.size,
              this.teacherIDWhoSubmittedWork)
            .then((response: any) => {

              if (response.error) {

                // Error inserting work document into work collection, so show to the user
                alert(response.error);

              } else if (
                response.task_id != this.task.task_id ||
                response.user_id != this.userIDToSaveWorkAs ||
                response.timestamp != this.timestampForLastUpload ||
                response.filesize != file.size) {

                alert("There was a problem uploading your work - please try again.");

              } else {

                // Success uploading work ...
                console.log("Success uploading work");

                // Navigate back to the task
                this.router.navigate(['/task/' + this.globals.user.school_id + '/'
                  + parseInt(this.route.snapshot.paramMap.get('task_id')), {}]);

              }

            });

        }
        else{
          this.setUploading(false);
          alert('Could not upload file.');
        }
      }
    };
    xhr.send(file);
  }


  goBack(): void {
    this.router.navigate(['/task/' + this.globals.user.school_id + '/' 
      + parseInt(this.route.snapshot.paramMap.get('task_id')), {}]);
  }

  toTitleCase(str) {
    return str.replace(/\w\S*/g, function(txt){
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

}
