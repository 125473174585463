import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import {Location} from '@angular/common';

import { TaskService }  from '../task/task.service';
import { Task } from 'app/task/task';
import { Globals } from '../globals';
import { UserService } from '../users/user.service';
import { TeacherService } from '../teacher/teacher.service';

@Component({
  selector: 'app-view-work',
  templateUrl: './view-work.component.html',
  styleUrls: ['./view-work.component.css']
})
export class ViewWorkComponent implements OnInit {

  task: Task;
  gettingTask: boolean = false;
  screenWork: boolean = false;

  superAccess : boolean = false;

  workArray: any[];

  constructor(
    private taskService: TaskService,
    private router: Router,
    private globals: Globals,
    private userService: UserService,
    private route: ActivatedRoute,
    private teacherService: TeacherService,
    private location: Location,
    ) { }

  ngOnInit() {

    var self = this;

    this.route.queryParams.subscribe(params => {
      // Defaults to false if no query param provided
      this.screenWork = (params['screen_work'] == "true") || false;
    });

    this.userService.loginLocalUserSession(this.route.snapshot.paramMap.get('school_id'), true, false, null, true, function(user) {

      // Get the expiry parameter if it exists (for super access) and ensure it hasn't elapsed ...

      var expiry = self.route.snapshot.paramMap.get('expiry');

      if (expiry != null) {

        if (self.isInt(expiry) && parseInt(expiry) > new Date().getTime()) {

          // Expiry exists and is in-date, so check against their user entry that they have super access
          // and that the expiry in the param matches the super_expiry in their entry

          self.teacherService
          .isSuperAccessExpiry(parseInt(expiry))
          .then((results: any) => {

            if (results.success == true) {

              // Continue to render the page as the expiry is valid ...

              self.superAccess = true;
              self.getTask();

            } else {

              self.router.navigate(['/home/', {}]);

            }

          });

        } else {

          self.router.navigate(['/home/', {}]);

        }

      } else {

        self.getTask();

      }

    });

  }

  isInt(n) {
    return n % 1 === 0;
  }

  getTask() {

    if (this.superAccess) {

      this.getWorkArray(true);

    } else if (this.route.snapshot.paramMap.get('expiry') == null) {

      this.getWorkArray(false);

    }

  }

  getWorkArray(isSuperAccess: boolean) {

    if (this.globals.user != null && this.globals.user.user_id != null && this.gettingTask == false) {

      this.gettingTask = true;

      const task_id: number = parseInt(this.route.snapshot.paramMap.get('task_id'));

      var userID = this.globals.user.user_id;
      if (isSuperAccess) {
        userID = this.route.snapshot.paramMap.get('teacher_id');
      }

      var schoolID = this.globals.user.school_id;
      if (isSuperAccess) {
        schoolID = this.route.snapshot.paramMap.get('school_id');
      }

      var superAccessTeacherID = null;
      if (isSuperAccess) {
        superAccessTeacherID = this.globals.user.user_id;
      }

      this.taskService
      .getTask(task_id, schoolID, userID)
      .then((task: Task) => {

        this.gettingTask = false;

        if (task != null && task.task_id == task_id) {

          this.task = task;

          this.teacherService
          .getWorkArray(schoolID, task.task_id)
          .then((workArray: any[]) => {

            this.workArray = workArray;

          });

        } else {

          if (this.globals.user) {
            this.goBack();
          } else {
            this.goHome();
          }
          
        }

      });

    }

  }

  onWorkItemDelete(workID: number) {

    this.teacherService
    .deleteWorkItem(this.globals.user.school_id, workID)
    .then((workArray: any[]) => {

      // Refresh the page by fetching the workArray again
      this.teacherService
        .getWorkArray(this.globals.user.school_id, this.task.task_id)
        .then((workArray: any[]) => {

          this.workArray = workArray;

        });

    });

  }

  decrement(task: Task) {

    const inputElement: HTMLInputElement = <HTMLInputElement> document.getElementById("minTimeToJudgeInputForTask" + task.task_id);
    var newValue = Math.max((parseInt(inputElement.value) - 1), 0);
    inputElement.value = "" + newValue;

    this.updateMinTimeToJudge(newValue, task);

  }

  increment(task: Task) {

    const inputElement: HTMLInputElement = <HTMLInputElement> document.getElementById("minTimeToJudgeInputForTask" + task.task_id);
    var newValue = Math.min((parseInt(inputElement.value) + 1), 120);
    inputElement.value = "" + newValue;

    this.updateMinTimeToJudge(newValue, task);
    
  }

  updateMinTimeToJudgeFromInput(event: any, task: Task) {

    this.updateMinTimeToJudge(parseInt(event.target.value), task);

  }

  updateMinTimeToJudge(value: number, task: Task) {

    if (value != null) {

      this.teacherService.updateMinTimeToJudgeTask(this.globals.user.school_id, task.task_id, value);

    }

  }

  onProceedToJudgePhase() {

    if (this.workArray[0].num_work_items < 2) {

      alert("At least two work items need to be submitted before you can move into the judge phase");
      this.router.navigate(['/teacher/dashboard/' + this.globals.user.school_id + '/', {}]);

    } else {

      this.teacherService.updateCanJudgeForTask(this.globals.user.school_id, this.task.task_id, true)
      .then((response: any[]) => {

        this.router.navigate(['/teacher/dashboard/' + this.globals.user.school_id + '/', {}]);

      });

    }

  }

  goBack(): void {

    var urlSuffix = "";
    var schoolID = this.globals.user.school_id;

    if (this.superAccess) {

      urlSuffix = this.route.snapshot.paramMap.get('teacher_id') + '/' + this.route.snapshot.paramMap.get('expiry') + '/';
      schoolID = this.route.snapshot.paramMap.get('school_id');

    }

    if (this.task && this.task.is_archived) {

      this.router.navigate(['/teacher/archived_tasks/' + schoolID + '/' + urlSuffix, {}]);

    } else {

      this.router.navigate(['/teacher/dashboard/' + schoolID + '/' + urlSuffix, {}]);

    }
    
  }

  goHome(): void {
    this.router.navigate(['/home/', {}]);
  }

}
