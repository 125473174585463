import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';

import { Task } from '../task/task';
import { Globals } from '../globals'

import * as fingerprint from 'fingerprintjs';

@Injectable({
  providedIn: 'root'
})
export class TeacherService {

  private teacherURL = '/api/teacher';

  constructor(private http: Http, private globals: Globals) { }

  // post("/api/teacher/login")
  teacherLogin(email: string, password: string, isSharedDevice: boolean): Promise<any> {

    localStorage.setItem("is_shared_device", "" + isSharedDevice);

    var loginDetails = {};
    loginDetails["email"] = email;
    loginDetails["password"] = password;
    loginDetails["fingerprint"] = (new fingerprint()).get();
    loginDetails["user_agent"] = navigator.userAgent;
    loginDetails["is_shared_device"] = isSharedDevice;

    return this.http.post(this.teacherURL + "/login", loginDetails)
                .toPromise()
                .then(response => response.json() as any)
                .catch(this.handleError);

  }

  // get("/api/teacher/data/:school_id")
  getDashboardData(userID: string, schoolID: string, teacherAccessToken: string, isArchived: boolean, superAccessTeacherID: string): Promise<any[]> {

    return this.http.get(this.teacherURL + '/data/' + schoolID + '?user_id=' + userID + '&teacher_access_token=' + teacherAccessToken +
      '&archived=' + isArchived + '&super_access_teacher_id=' + superAccessTeacherID)
                .toPromise()
                .then(response => response.json() as any[])
                .catch(this.handleError);

  }

  // get("/api/teacher/edit_school_data/:school_id")
  getEditSchoolData(teacherID: string, schoolID: string, teacherAccessToken: string, superAccessTeacherID: string): Promise<any[]> {

    return this.http.get(this.teacherURL + '/edit_school_data/' + schoolID + '/' + teacherID + '?teacher_access_token=' + teacherAccessToken +
      '&super_access_teacher_id=' + superAccessTeacherID)
                .toPromise()
                .then(response => response.json() as any[])
                .catch(this.handleError);

  }

  private handleError (error: any): Promise<any> {
    let errMsg = (error.message) ? error.message :
    error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(errMsg); // log to console
    return Promise.reject(errMsg);
  }

  // post("/api/teacher/create_task")
  createTask(subject: string, _class: string, title: string): Promise<any> {

    var newTask: Task = new Task();
    newTask.subject = subject;
    newTask.class = _class;
    newTask.title = title;
    newTask.teacher_id = this.globals.user.user_id;
    newTask.teacher_name = this.globals.user.name;
    newTask.school_id = this.globals.user.school_id;

    return this.http.post(this.teacherURL + "/create_task?teacher_access_token=" +
      (!this.globals.user ? "null" : this.globals.user.teacher_access_token), newTask)
    .toPromise()
    .then(response => response.json() as any)
    .catch(this.handleError);

  }

  // put("/api/teacher/update_can_submit/:school_id")
  updateCanSubmitForTask(schoolID: string, taskID: number, canSubmit: boolean) {

    return this.http.put(this.teacherURL + "/update_can_submit/" + schoolID + "?teacher_access_token=" +
      (!this.globals.user ? "null" : this.globals.user.teacher_access_token),
      {task_id: taskID, can_submit: canSubmit})
    .toPromise()
    .then(response => response.json() as any)
    .catch(this.handleError);

  }

  // put("/api/teacher/update_can_judge/:school_id")
  updateCanJudgeForTask(schoolID: string, taskID: number, canJudge: boolean) {

    return this.http.put(this.teacherURL + "/update_can_judge/" + schoolID + "?teacher_access_token=" +
      (!this.globals.user ? "null" : this.globals.user.teacher_access_token),
      {task_id: taskID, can_judge: canJudge})
    .toPromise()
    .then(response => response.json() as any)
    .catch(this.handleError);

  }

  // put("/api/teacher/min_time_to_judge/:school_id")
  updateMinTimeToJudgeTask(schoolID: string, taskID: number, minTimeToJudgeTaskInSeconds: number) {

    return this.http.put(this.teacherURL + "/min_time_to_judge/" + schoolID + "?teacher_access_token=" +
      (!this.globals.user ? "null" : this.globals.user.teacher_access_token),
      {task_id: taskID, min_time_to_judge_in_seconds: minTimeToJudgeTaskInSeconds})
    .toPromise()
    .then(response => response.json() as any)
    .catch(this.handleError);

  }

  // get("/api/teacher/judgement_data/:school_id/:task_id")
  getJudgementData(schoolID: string, taskID: number): Promise<any[]> {

    return this.http.get(this.teacherURL + '/judgement_data/' + schoolID + "/" + taskID + '?teacher_access_token=' +
      (!this.globals.user ? "null" : this.globals.user.teacher_access_token))
    .toPromise()
    .then(response => response.json() as any[])
    .catch(this.handleError);

  }

  // get("/api/teacher/view_work/:school_id/:task_id)"
  getWorkArray(schoolID: string, taskID: number): Promise<any[]> {

    return this.http.get(this.teacherURL + '/view_work/' + schoolID + "/" + taskID + '?teacher_access_token=' +
      ((!this.globals.user ? "null" : this.globals.user.teacher_access_token)))
    .toPromise()
    .then(response => response.json() as any[])
    .catch(this.handleError);

  }

  // delete("/api/teacher/delete_work/:school_id/:work_id)"
  deleteWorkItem(schoolID: string, workID: number) : Promise<any> {

    return this.http.delete(this.teacherURL + '/delete_work/' + schoolID + "/" + workID + '?teacher_access_token=' +
      (!this.globals.user ? "null" : this.globals.user.teacher_access_token))
    .toPromise()
    .then(response => response.json() as any[])
    .catch(this.handleError);

  }

  // get("/api/teacher/view_judgements/:school_id/:task_id)" - not used directly
  getJudgementsArray(schoolID: string, taskID: number): Promise<any[]> {

    return this.http.get(this.teacherURL + '/view_judgements/' + schoolID + '/' + taskID + '?teacher_access_token=' +
      (!this.globals.user ? "null" : this.globals.user.teacher_access_token))
    .toPromise()
    .then(response => response.json() as any[])
    .catch(this.handleError);

  }

  // app.get("/api/teacher/view_results/:school_id/:task_id")
  getResultsArray(schoolID: string, taskID: number): Promise<any[]> {

    return this.http.get(this.teacherURL + '/view_results/' + schoolID + '/' + taskID + '?teacher_access_token=' +
      (!this.globals.user ? "null" : this.globals.user.teacher_access_token))
    .toPromise()
    .then(response => response.json() as any[])
    .catch(this.handleError);

  }

  // app.get("/api/teacher/view_judges/:school_id/:task_id")
  getJudgesArray(schoolID: string, taskID: number): Promise<any[]> {

    return this.http.get(this.teacherURL + '/view_judges/' + schoolID + '/' + taskID + '?teacher_access_token=' +
      (!this.globals.user ? "null" : this.globals.user.teacher_access_token))
    .toPromise()
    .then(response => response.json() as any[])
    .catch(this.handleError);

  }

  // delete("/api/teacher/delete_judgements/:school_id/:task_id/:judge_user_id)"
  deleteJudgements(schoolID: string, taskID: number, judgeUserID: number) : Promise<any> {

    return this.http.delete(this.teacherURL + '/delete_judgements/' + schoolID + '/' + taskID + '/' + judgeUserID +
      '?teacher_access_token=' + (!this.globals.user ? "null" : this.globals.user.teacher_access_token))
    .toPromise()
    .then(response => response.json() as any[])
    .catch(this.handleError);

  }

  // put("/api/teacher/archive_task/:school_id)"
  archiveTask(schoolID: string, taskID: number, isArchived: boolean) : Promise<any> {

    return this.http.put(this.teacherURL + '/archive_task/' + schoolID + '?teacher_access_token=' +
      (!this.globals.user ? "null" : this.globals.user.teacher_access_token),
      {task_id: taskID, archived: isArchived})
    .toPromise()
    .then(response => response.json() as any[])
    .catch(this.handleError);

  }

  // delete("/api/teacher/delete_task/:school_id/:task_id)"
  deleteTask(schoolID: string, taskID: number) : Promise<any> {

    return this.http.delete(this.teacherURL + '/delete_task/' + schoolID + '/' + taskID + '?teacher_access_token=' +
      (!this.globals.user ? "null" : this.globals.user.teacher_access_token))
    .toPromise()
    .then(response => response.json() as any[])
    .catch(this.handleError);

  }

  // put("/api/teacher/set_contact_for_feedback")
  setCanContactForFeedback(canContact: boolean) {

    return this.http.put(this.teacherURL + '/set_contact_for_feedback/' + this.globals.user.user_id + '?teacher_access_token=' +
    (!this.globals.user ? "null" : this.globals.user.teacher_access_token),
    { can_contact: canContact })
    .toPromise()
    .then(response => response.json())
    .catch(this.handleError);

  }

  // put("/api/teacher/set_super_access_expiry")
  setSuperAccessExpiry() {

    return this.http.put(this.teacherURL + '/set_super_access_expiry/' + this.globals.user.user_id + '?teacher_access_token=' +
    (!this.globals.user ? "null" : this.globals.user.teacher_access_token),{})
    .toPromise()
    .then(response => response.json())
    .catch(this.handleError);

  }

  // get("/api/teacher/is_super_access_expiry")
  isSuperAccessExpiry(expiry: number): Promise<any[]> {

    return this.http.get(this.teacherURL + '/is_super_access_expiry/' +
    ((this.globals.user != null) ? this.globals.user.user_id : "null") +
    '/' + expiry +
    '?teacher_access_token=' + (!this.globals.user ? "null" : this.globals.user.teacher_access_token))
    .toPromise()
    .then(response => response.json())
    .catch(this.handleError);

  }

  // get("/api/teacher/schools_stats")
  getSchoolsStats(teacherID: string, teacherAccessToken: string, order: string): Promise<any[]> {

    return this.http.get(this.teacherURL + '/schools_stats?teacher_id=' + teacherID +
      '&teacher_access_token=' + teacherAccessToken +
      '&order=' + order)
    .toPromise()
    .then(response => response.json() as any[])
    .catch(this.handleError);

  }

  // get("/api/teacher/teachers_stats")
  getTeachersStats(teacherID: string, schoolID: string, teacherAccessToken: string, order: string): Promise<any[]> {

    return this.http.get(this.teacherURL + '/teachers_stats?teacher_id=' + teacherID +
      '&school_id=' + schoolID +
      '&teacher_access_token=' + teacherAccessToken +
      '&order=' + order)
    .toPromise()
    .then(response => response.json() as any[])
    .catch(this.handleError);

  }

  // get("/api/teacher/get_teacher_data")
  getTeacherData(teacherID: string, teacherAccessToken: string): Promise<any[]> {

    return this.http.get(this.teacherURL + '/get_teacher_data?' + 
      'teacher_id=' + teacherID + '&teacher_access_token=' + teacherAccessToken)
    .toPromise()
    .then(response => response.json() as any[])
    .catch(this.handleError);

  }

  // app.post("/api/teacher/request_password_change")
  requestPasswordChange(
    email: string,
    password: string,
  ): Promise<any> {

    var accountDetails = {};
    accountDetails["email"] = email;
    accountDetails["password"] = password;
    accountDetails["fingerprint"] = (new fingerprint()).get();
    accountDetails["user_agent"] = navigator.userAgent;

    return this.http.post(this.teacherURL + "/request_password_change", accountDetails)
    .toPromise()
    .then(response => response.json() as any)
    .catch(this.handleError);

  }

  // app.put("/api/teacher/approve_password_change/:teacher_id")
  approvePasswordChange(
    passwordChangeTeacherID: string,
    approve: boolean
  ) {

    return this.http.put(this.teacherURL + '/approve_password_change/' +
      (!this.globals.user ? "null" : this.globals.user.user_id) +
      '?teacher_access_token=' + (!this.globals.user ? "null" : this.globals.user.teacher_access_token),
      { password_change_teacher_id: passwordChangeTeacherID, approve: approve })
        .toPromise()
        .then(response => response.json())
        .catch(this.handleError);

  }

  // app.put("/api/teacher/change_school/:user_id")
  changeSchool(
    teacherID: string,
    newSchoolID: string
  ) {

    return this.http.put(this.teacherURL + '/change_school/' +
      (!this.globals.user ? "null" : this.globals.user.user_id) +
      '?teacher_access_token=' + (!this.globals.user ? "null" : this.globals.user.teacher_access_token),
      { teacher_id: teacherID, new_school_id: newSchoolID })
        .toPromise()
        .then(response => response.json())
        .catch(this.handleError);

  }

  // app.put("/api/teacher/update_school_info/:user_id")
  updateSchoolInfo(
    schoolID: string,
    newSchoolName: string,
    newSchoolLocation: string
  ) {

    return this.http.put(this.teacherURL + '/update_school_info/' +
      (!this.globals.user ? "null" : this.globals.user.user_id) +
      '?teacher_access_token=' + (!this.globals.user ? "null" : this.globals.user.teacher_access_token),
      { school_id: schoolID, new_school_name: newSchoolName, new_school_location: newSchoolLocation })
        .toPromise()
        .then(response => response.json())
        .catch(this.handleError);

  }

}
